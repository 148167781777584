import { useParams } from 'react-router'
import { ErrorBoundary } from 'shared'
import { CampaignPage } from './CampaignPage'
import { CampaignOverview } from '../../components/partials/dashboard/Overview'
import { useCampaign } from '../../hooks'

type Props = { campaignId: number }
export const OverviewPage = (props: Props) => {
  const { data: campaign, isSuccess } = useCampaign(props.campaignId)

  return <CampaignPage>{isSuccess && <CampaignOverview campaign={campaign} />}</CampaignPage>
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <OverviewPage campaignId={campaignId} />
}

export const OverviewPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
