import { UserStatus } from 'shared'
import { ICampaign } from 'campaign'
import { Client } from 'client'

export interface IUser {
  id?: number
  client: Client
  clients: Client[]
  email: string
  firstName: string
  lastName: string
  name?: string | undefined
  identityId?: string
  userAttributes?: any
  userStatus?: UserStatus
  avatar?: any
  campaigns: ICampaign[]
  type?: string
  jobTitle?: string
  enabled: boolean
}

export class User implements IUser {
  public id
  public client
  public clients
  public email
  public firstName
  public lastName
  public name?: string | undefined
  public identityId
  public userAttributes
  public userStatus
  public avatar?: any
  public campaigns
  public type
  public jobTitle
  public enabled

  constructor(user: any) {
    this.id = user.id
    this.client = user.client
    this.clients = user.clients
    this.email = user.email || user.UserAttributes.find((attr: any) => attr.Name === 'email')?.Value
    this.firstName = user.firstName || 'Ospace'
    this.lastName = user.lastName || 'User'
    this.name = `${this.firstName} ${this.lastName}`
    this.avatar = `${this.firstName[0]}${this.lastName[0]}`
    this.identityId = user.identityId
    this.userAttributes = user.UserAttributes
    this.userStatus = UserStatus[user.UserStatus as keyof typeof UserStatus]
    this.campaigns = user.campaigns
    this.type = user.type
    this.jobTitle = user.jobTitle
    this.enabled = user.Enabled
  }
}
