import { useEffect, useState } from 'react'
import BTable from 'react-bootstrap/Table'
import {
  Column,
  Table as ReactTable,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
} from '@tanstack/react-table'
import { KTSVG } from '../../../_metronic/helpers'

export type TableProps = {
  columns: any
  data: any
  hiddenColumns?: string[]
}

export type PaginationProps = {
  table: ReactTable<unknown>
}

const Pagination = ({ table }: PaginationProps) => {
  return (
    <div className='col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-start my-10'>
      <div className='pagination'>
        <div className='input-group input-group-sm'>
          <button
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.setPageIndex(0)}
            disabled={!table.getCanPreviousPage()}
          >
            <i className='bi bi-chevron-double-left'></i>
          </button>
          <button
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            <i className='bi bi-chevron-left'></i>
          </button>
          <button
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            <i className='bi bi-chevron-right'></i>
          </button>
          <button
            className='btn btn-secondary border rounded p-2 ps-3 m-1'
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
            disabled={!table.getCanNextPage()}
          >
            <i className='bi bi-chevron-double-right'></i>
          </button>

          <select
            className='form-select rounded mx-2 my-1'
            value={table.getState().pagination.pageSize}
            onChange={(e) => {
              table.setPageSize(Number(e.target.value))
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>

          <span className='flex items-center gap-1 m-1'>
            <div>Page</div>
            <strong>
              {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
            </strong>
          </span>
        </div>
      </div>
    </div>
  )
}

// A debounced search input react component
const DebouncedSearchInput = ({
  value: initialValue,
  onChange,
  debounce = 200,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) => {
  const [value, setValue] = useState(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return <input {...props} value={value} onChange={(e) => setValue(e.target.value)} />
}

export const Table = ({ columns, data, hiddenColumns }: TableProps) => {
  const [globalFilter, setGlobalFilter] = useState('')
  const [sorting, setSorting] = useState<SortingState>([])

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    debugTable: false,
    autoResetAll: false,
  })

  useEffect(() => {
    hiddenColumns?.forEach((col) => table.getColumn(col)?.toggleVisibility(false))
  }, [])

  // Render the UI for your table
  return (
    <>
      <div className='d-flex align-items-center position-relative mb-5'>
        <span className='svg-icon svg-icon-1 position-absolute ms-6'>
          <KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-2x mt-1' />
        </span>

        <DebouncedSearchInput
          value={globalFilter ?? ''}
          onChange={(value) => setGlobalFilter(String(value))}
          className='form-control form-control-solid w-300px ps-16'
          placeholder='Search'
        />
      </div>
      <BTable bordered responsive size='sm' style={{ color: '#eee' }} className='flushed'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'
            >
              {headerGroup.headers.map((header) => (
                <th key={header.id} colSpan={header.colSpan} className='fw-bolder text-muted '>
                  {header.isPlaceholder ? null : (
                    <div
                      {...{
                        className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                        onClick: header.column.getToggleSortingHandler(),
                        style: {
                          minWidth: `200px`,
                        },
                      }}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      {{
                        asc: <i className='bi bi-caret-down-square mx-2'></i>,
                        desc: <i className='bi bi-caret-up-square mx-2'></i>,
                      }[header.column.getIsSorted() as string] ?? null}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className='fw-bolder text-light bg-white'>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  <div className='text-dark fw-bolder d-block mt-2 fs-6'>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </BTable>
      <Pagination table={table} />
    </>
  )
}
