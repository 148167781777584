import { Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { CampaignPage } from './CampaignPage'
import { ErrorBoundary } from 'shared'

import { CampaignService } from '../../services'

type IntelType = 'intent' | 'intent_and_engagement' | 'contact'
type Props = {
  campaignId: number
  intelType: IntelType
}
export const DataIntelPage = (props: Props) => {
  const { data: campaign, isFetching } = useQuery(
    ['campaign', props.campaignId + props.intelType],
    () => CampaignService.getCampaign(props.campaignId),
    {
      refetchOnMount: true,
    }
  )

  let lookerUrl
  if (props.intelType === 'intent') {
    // lookerUrl = campaign?.lookerDashboardForIntents
    lookerUrl = campaign?.lookerDashboardForIntent
  } else if (props.intelType === 'intent_and_engagement') {
    // lookerUrl = campaign?.lookerDashboardForCompanies
    lookerUrl = campaign?.lookerDashboardForIntentAndEngagement
  } else if (props.intelType === 'contact') {
    // lookerUrl = campaign?.lookerDashboardForMeetings
    lookerUrl = campaign?.lookerDashboardForContact
  }
  return (
    <CampaignPage>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <iframe title='intent' width='100%' style={{ height: '100vh' }} src={lookerUrl} />
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const { id, intelType }: any = useParams()
  return <DataIntelPage campaignId={Number(id)} intelType={intelType} />
}

export const DataIntelPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
