import { Spinner } from 'react-bootstrap'
import { userTypes, ErrorBoundary } from 'shared'
import { useCheckRole } from 'auth'

import { PageTitle } from '../../../_metronic/layout/core'
import { ClientsTable } from '../components/Clients'
import { useClients } from '../hooks'

export const ClientPage = () => {
  const { status, data: clients, error, isFetching } = useClients()

  return (
    <>
      <PageTitle>Clients</PageTitle>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : status === 'error' ? (
        <span>Error: {(error as any).message}</span>
      ) : clients ? (
        <ClientsTable clients={clients} />
      ) : (
        <span>No Clients</span>
      )}
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.ClientViewer])
  return <ClientPage />
}

export const ClientPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
