import { API } from 'aws-amplify'
import { EditUserDTO } from '../schemas'

// TODO: circular import
import { IUser, User } from '../types'

const getUsers = async () => {
  const users = await API.get('client', `/users`, {})
  return users.map((user: IUser) => new User(user))
}

const getUser = async (identityId: string) => {
  const user = await API.get('client', `/users/${identityId}`, {})
  return new User(user)
}

const createUser = async (user: any) => {
  return API.post('client', `/users`, {
    body: {
      username: user.email,
      ...user,
    },
  })
}

const editUser = async (identityId: string, user: EditUserDTO) => {
  return API.put('client', `/users/${identityId}`, {
    body: user,
  })
}

const createSSOUser = (user: any) => {
  return API.post('client', `/users:create-sso`, {
    body: user,
  })
}

const confirmUser = async (passwords: any) => {
  return API.post('public', `/users:confirm`, {
    body: passwords,
  })
}

const forgotPassword = async (passwordDTO: any) => {
  return API.post('public', `/users:forgot-password`, {
    body: passwordDTO,
  })
}

const confirmForgotPassword = async (passwordDTO: any) => {
  return API.post('public', `/users:confirm-forgot-password`, {
    body: passwordDTO,
  })
}

const inviteUser = async (username: string) => {
  return API.post('client', `/users:invite`, {
    body: {
      username,
    },
  })
}

const enableUser = async (username: string) => {
  return API.patch('client', `/users/${username}/enabled`, {
    body: {
      enabled: true,
    },
  })
}

const disableUser = async (username: string) => {
  return API.patch('client', `/users/${username}/enabled`, {
    body: {
      enabled: false,
    },
  })
}

const editProfile = async (username: string, user: Partial<EditUserDTO>) => {
  return API.put('client', `/users/${username}`, {
    body: user,
  })
}

export const UserService = {
  getUser,
  getUsers,
  createUser,
  createSSOUser,
  confirmUser,
  forgotPassword,
  confirmForgotPassword,
  editUser,
  inviteUser,
  enableUser,
  disableUser,
  editProfile,
}
