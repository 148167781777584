import * as Yup from 'yup'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { ErrorMessage, Field, Formik, Form } from 'formik'
import { useNotificationContext, PasswordField } from 'shared'

import { AuthLayout } from '../components/AuthLayout'
import { UserService } from '../services/user'

const initialValues = {
  confirmationCode: '',
  password: '',
  confirmPassword: '',
}

const forgotPasswordSchema = Yup.object().shape({
  confirmationCode: Yup.number().required('Code is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 letters')
    .max(50, 'Maximum 50 letters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .min(8, 'Minimum 8 letters')
    .max(50, 'Maximum 50 letters')
    .required('Confirm Password is required'),
})

export function ConfirmForgotPassword() {
  const { setNotification } = useNotificationContext()
  const history = useHistory()
  const location: any = useLocation()
  const mutation = useMutation(
    (values: any) => {
      return UserService.confirmForgotPassword({
        email: location?.state?.email,
        confirmationCode: values.confirmationCode,
        password: values.password,
      })
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'Your password has been sucessfully changed.',
          type: 'success',
        })
        history.push('/auth/login')
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  const onSubmit = async (values: any) => {
    await mutation.mutateAsync(values)
  }

  const validateConfirmPassword = (pass: any, value: any) => {
    let error = ''
    if (pass && value) {
      if (pass !== value) {
        error = 'Password not matched'
      }
    }
    return error
  }

  return (
    <AuthLayout>
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='form w-100'>
            <div className='mb-10'>
              <div className='text-center text-dark fw-bold fs-4'>Confirm password reset</div>
              <div className='text-success fw-bold fs-6'>
                Please check your email for the confirmation code.
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-6 fw-bolder text-gray-900 mb-2'>
                <span className='required'>Confirmation code</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='confirmationCode'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='confirmationCode' />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-6 fw-bolder text-gray-900 mb-2'>
                <span className='required'>Password</span>
              </label>

              <Field
                type='password'
                component={PasswordField}
                className='form-control form-control-lg form-control-solid'
                name='password'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='password' />
              </div>
            </div>

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-6 fw-bolder text-gray-900 mb-2'>
                <span className='required'>Confirm Password</span>
              </label>

              <Field
                type='password'
                component={PasswordField}
                className='form-control form-control-lg form-control-solid'
                name='confirmPassword'
                validate={(value: any) => validateConfirmPassword(values.password, value)}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='confirmPassword' />
              </div>
            </div>

            <div className='text-center'>
              <button
                type='submit'
                className='btn btn-lg btn-primary me-3'
                disabled={mutation.isLoading}
              >
                {mutation.isLoading && (
                  <span className='spinner-border spinner-border-sm align-middle mx-2' />
                )}
                <span className='indicator-label'>Submit</span>
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}
