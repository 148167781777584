import { FC } from 'react'
import { useCheckRole } from 'auth'
import { userTypes, ErrorBoundary } from 'shared'

import { PageTitle } from '../../../_metronic/layout/core'
import { CampaignForm } from '../components/create-campaign/CampaignForm'

const CreateCampaignPage: FC = () => {
  return (
    <>
      <PageTitle>Create Campaign</PageTitle>
      <CampaignForm type='create' />
    </>
  )
}

export { CreateCampaignPage }

export const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  return <CreateCampaignPage />
}

export const CreateCampaignPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
