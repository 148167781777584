/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'
import { CampaignStatus } from '../../../models/Campaign'

const Step1: FC<any> = ({ type, values }) => {
  return (
    <div className='w-100'>
      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span className='required'>Campaign Name</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your unique campaign name'
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='name'
          placeholder=''
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='name' />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
          <span>Description</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your unique campaign description'
          ></i>
        </label>

        <Field
          type='text'
          className='form-control form-control-lg form-control-solid'
          name='description'
          placeholder=''
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='description' />
        </div>
      </div>

      <div className='row  mb-10'>
        <div className='col-md-6 fv-row'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Start date</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify start date of the campaign'
            ></i>
          </label>

          <Field
            type='date'
            className='form-control form-control-lg form-control-solid'
            name='startDate'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='startDate' />
          </div>
        </div>
        <div className='col-md-6 fv-row'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>End date</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify end date of the campaign'
            ></i>
          </label>

          <Field
            type='date'
            className='form-control form-control-lg form-control-solid'
            name='endDate'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='endDate' />
          </div>
        </div>
      </div>

      {type == 'edit' && (
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Status</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your campaign status'
            ></i>
          </label>
          <Field
            name='status'
            as='select'
            className='form-select form-control form-control-lg form-control-solid'
          >
            <option
              value={CampaignStatus.InProgress}
              defaultChecked={values.status == CampaignStatus.InProgress}
            >
              {CampaignStatus.InProgress}
            </option>
            <option
              value={CampaignStatus.Paused}
              defaultChecked={values.status == CampaignStatus.Paused}
            >
              {CampaignStatus.Paused}
            </option>
            <option
              value={CampaignStatus.Ended}
              defaultChecked={values.status == CampaignStatus.Ended}
            >
              {CampaignStatus.Ended}
            </option>
          </Field>
          <div className='text-danger mt-2'>
            <ErrorMessage name='status' />
          </div>
        </div>
      )}
    </div>
  )
}

export { Step1 }
