import React, { FC } from 'react'

interface IProps {
  count: {
    icon: string
    count: number
    title: string
  }
}

export const SubWidget: FC<IProps> = (props: IProps) => {
  const { count } = props

  return (
    <div className='col'>
      <div className='d-flex align-items-center me-2'>
        <div className='symbol symbol-50px me-3'>
          <div className='symbol-label bg-light-info'>
            <span className='svg-icon svg-icon-1 svg-icon-info'>
              <i className={`bi bi-${count.icon} fs-2x text-success`}></i>
            </span>
          </div>
        </div>
        <div>
          <div className='fs-4 text-dark fw-bolder'>{count.count}</div>
          <div className='fs-7 text-muted fw-bold'>{count.title}</div>
        </div>
      </div>
    </div>
  )
}
