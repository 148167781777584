import { useMutation, useQueryClient } from 'react-query'
import { useNotificationContext } from 'shared'
import { UserService, EditUserProfileDTO } from 'auth'

export const useUserProfileMutation = () => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    (user: EditUserProfileDTO) => UserService.editProfile(user?.identityId as string, user),
    {
      onSuccess: (data) => {
        setNotification({
          active: true,
          message: 'You have successfully updated user profile.',
          type: 'success',
        })
        queryClient.invalidateQueries(['user', data?.identityId])
        queryClient.refetchQueries(['users'])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response.data || e.message,
          type: 'danger',
        })
      },
    }
  )
}
