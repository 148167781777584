import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { SubWidget } from './SubWidget'

interface IProps {
  widget: {
    title: string
    link: string
    disabled?: boolean
    sub?: any
  }
}

export const Widget: FC<IProps> = (props: IProps) => {
  const { widget } = props
  const disabled = widget.disabled ? 'bg-secondary' : 'bg-white'

  return (
    <div className='col-xl-4'>
      <Link to={widget.link}>
        <div className={`card card-xl-stretch mb-xl-8 ${disabled}`}>
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <span className='card-label fw-bolder fs-3 mb-1'>{widget.title}</span>
            </h3>
          </div>
          <div className='card-body p-0 d-flex flex-column' style={{ position: 'relative' }}>
            <div className='card-px pt-5 pb-5 flex-grow-1'>
              <div className='row g-0 mt-3 mb-10'>
                {widget?.sub[0] && <SubWidget count={widget.sub[0]} />}
                {widget?.sub[1] && <SubWidget count={widget.sub[1]} />}
              </div>
              <div className='row g-0 mb-10'>
                {widget?.sub[2] && <SubWidget count={widget.sub[2]} />}
                {widget?.sub[3] && <SubWidget count={widget.sub[3]} />}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
