import React from 'react'

import { Form } from 'formik'
import { Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'

export const ContactUploadForm: React.FC<any> = (props: any) => {
  const { mutation, form, cancelLink } = props
  return (
    <Form className='form' noValidate id='kt_upload_contact_form'>
      <div className='card-body fv-row mb-10'>
        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Upload Date</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify upload date'
            ></i>
          </label>

          <Field
            type='date'
            className='form-control form-control-lg form-control-solid'
            name='uploadDate'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='uploadDate' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>File to upload(CSV)</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Choose the csv file to upload and import contacts'
            ></i>
          </label>

          <Field
            type='file'
            className='form-control form-control-lg form-control-solid'
            name='csv'
            placeholder=''
            value={undefined}
            onChange={(event: any) => {
              form.setFieldValue('file', event.currentTarget.files[0])
              form.setFieldValue('csv', event.currentTarget.files[0].name)
            }}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='csv' />
          </div>
        </div>

        <div className='mb-10'>
          <div className='mb-0 fv-row'>
            <label className='d-flex align-items-center form-label mb-5'>Select Account Type</label>

            <div className='mb-0'>
              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>Net New</span>
                    <span className='fs-6 fw-bold text-gray-400'>
                      Contacts found for newly discovered accounts
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='accountType'
                    value='Net New'
                  />
                </span>
              </label>

              <label className='d-flex flex-stack mb-5 cursor-pointer'>
                <span className='d-flex align-items-center me-2'>
                  <span className='d-flex flex-column'>
                    <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                      Target Account
                    </span>
                    <span className='fs-6 fw-bold text-gray-400'>
                      Contacts found for targeted accounts
                    </span>
                  </span>
                </span>

                <span className='form-check form-check-custom form-check-solid'>
                  <Field
                    className='form-check-input'
                    type='radio'
                    name='accountType'
                    value='Target Account'
                  />
                </span>
              </label>
            </div>

            <div className='text-danger mt-2'>
              <ErrorMessage name='accountType' />
            </div>
          </div>
        </div>

        <div className='card-footer'>
          <button type='submit' className='btn btn-primary' disabled={mutation.isLoading}>
            {mutation.isLoading && (
              <span className='spinner-border spinner-border-sm align-middle mx-2' />
            )}
            Submit
          </button>
          <Link to={cancelLink} className='btn btn-secondary m-10'>
            Cancel
          </Link>
        </div>
      </div>
    </Form>
  )
}
