import React from 'react'

import { Form } from 'formik'
import { Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'

const ClientForm: React.FC<any> = (props: any) => {
  const { mutation, form } = props
  return (
    <Form className='form' noValidate id='kt_create_account_form'>
      <div className='card-body fv-row mb-10'>
        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique client name'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='name'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='name' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Website</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your website'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='website'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='website' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Logo</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your website'
            ></i>
          </label>

          <Field
            type='file'
            className='form-control form-control-lg form-control-solid'
            name='logo'
            placeholder=''
            onChange={(event: any) => {
              form.setFieldValue('file', event.currentTarget.files[0])
            }}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='logo' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='form-check form-switch form-check-custom form-check-solid'>
            <Field
              name='active'
              className='form-check-input'
              type='checkbox'
              onChange={(e: any) => form.setFieldValue('active', e.target.checked)}
            />
            <span className='form-check-label fw-bold text-gray-400'>Active</span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='active' />
          </div>
        </div>

        <div className='card-footer'>
          <button type='submit' className='btn btn-primary' disabled={mutation.isLoading}>
            {mutation.isLoading && (
              <span className='spinner-border spinner-border-sm align-middle mx-2' />
            )}
            Submit
          </button>
          <Link to='/client' className='btn btn-secondary m-10'>
            Cancel
          </Link>
        </div>
      </div>
    </Form>
  )
}

export { ClientForm }
