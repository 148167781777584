import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { Formik } from 'formik'
import { useNotificationContext, ErrorBoundary } from 'shared'

import { PageTitle } from '../../../_metronic/layout/core'
import { ContactService } from '../services'
import { inits, uploadContactSchema } from '../components/UploadContactHelper'
import { ContactUploadForm } from '../components/ContactForm'
import { useCheckRole } from 'auth'
import { userTypes } from 'shared'
import { useCampaign } from 'campaign/hooks'
import { useState } from 'react'
import { CampaignPage } from 'campaign/pages/CampaignPage/CampaignPage'

type Props = {
  campaignId: number
}
export const ContactUploadPage = (props: Props) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()
  const [payload, setPayload] = useState({})
  const history = useHistory()
  const { data: campaign } = useCampaign(props.campaignId)
  const mutation = useMutation(
    (contact: any) => {
      contact.campaignId = props.campaignId
      contact.lookerClientId = campaign?.lookerClientId
      return ContactService.validateContact(contact)
    },
    {
      onSuccess: (data) => {
        setNotification({
          active: true,
          message: 'Please review your contacts before uploading.',
          type: 'success',
        })
        history &&
          history.push(`/campaign/${props.campaignId}/contact/review`, {
            data,
            payload,
          })
        queryClient.invalidateQueries(['contacts', props.campaignId])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  return (
    <CampaignPage>
      <PageTitle>Upload Contacts</PageTitle>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>Upload Contacts CSV</span>
          </h3>
        </div>
        <Formik
          initialValues={inits}
          validationSchema={uploadContactSchema}
          onSubmit={async (values, actions) => {
            setPayload(values)
            await mutation.mutateAsync(values as any)
            actions.resetForm()
          }}
        >
          {(formProps) => (
            <ContactUploadForm
              form={formProps}
              mutation={mutation}
              cancelLink={`/campaign/view/${props.campaignId}`}
            />
          )}
        </Formik>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  const params: any = useParams()
  return <ContactUploadPage campaignId={params?.id} />
}

export const ContactUploadPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
