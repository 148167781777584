import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useParams } from 'react-router'

import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { Fixer } from '../../../_metronic/partials/widgets'
import { Campaigns } from '../components/Campaigns'
import { CampaignsHeader } from '../components/partials/CampaignsHeader'
import { useClientCampaign } from '../hooks'
import { useClient, useClients } from 'client'

// TODO: this component doubles as a page (it is imported by PrivateRoutes),
// and a component inside a page (it is conditionally rendered by `HomePage`.
const CampaignsPage: React.FC = () => {
  const params: any = useParams()

  // TODO: document what all this is about. Is it related to this component
  // doing double duty as a page and a component within a page?
  const givenClientId = Number(params?.id)

  const clients = useClients(!!!givenClientId)

  const client = useClient(givenClientId)

  let clientId
  if (clients.data) {
    clientId = clients?.data[0]?.id
  }
  if (client.data) {
    clientId = client.data?.id
  }

  const campaigns = useClientCampaign(clientId)

  let currentClient
  let isClientSuccess = false
  if (clients.isSuccess) {
    isClientSuccess = true
    currentClient = clients.data[0]
  }
  if (client.isSuccess) {
    isClientSuccess = true
    currentClient = client.data
  }

  const breadCrumbs: Array<PageLink> = [
    {
      title: 'Home',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
    {
      title: 'Clients',
      path: '/client',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  return (
    <>
      {campaigns.isFetching || clients.isFetching || client.isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : campaigns.status === 'error' ? (
        <span>Error: {(campaigns.error as any).message}</span>
      ) : (
        <>
          <Fixer />
          {isClientSuccess && campaigns.isSuccess && (
            <CampaignsHeader client={currentClient} campaigns={campaigns.data} />
          )}
          <PageTitle breadcrumbs={breadCrumbs}>Overview</PageTitle>
          {campaigns.isSuccess && <Campaigns campaigns={campaigns.data} client={currentClient} />}
        </>
      )}
    </>
  )
}

export { CampaignsPage }
