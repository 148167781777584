import { useQuery } from 'react-query'
import { ClientService } from '../services'

export const useClients = (enabled?: boolean) => {
  return useQuery('clients', ClientService.getClients, {
    enabled,
  })
}

export const useClient = (clientId: number) => {
  return useQuery(['client', clientId], () => ClientService.getClient(clientId), {
    enabled: !!clientId,
  })
}
