/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { AsideMenuItem } from './AsideMenuItem'
import { useUserRole } from 'auth'
import { userTypes } from 'shared'

export function AsideMenuMain() {
  const intl = useIntl()
  const role: any = useUserRole()

  return (
    <>
      {/* Top Level Menus */}
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/art/art002.svg'
        title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        fontIcon='bi-app-indicator'
      />

      {(role === userTypes.Admin ||
        role === userTypes.ClientManager ||
        role === userTypes.ClientViewer) && (
        <AsideMenuItem
          to='/client'
          title='Client Management'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        />
      )}

      {role === userTypes.Admin && (
        <AsideMenuItem
          to='/user'
          title='User Management'
          icon='/media/icons/duotune/communication/com006.svg'
          fontIcon='bi-person'
        />
      )}
    </>
  )
}
