import { Spinner } from 'react-bootstrap'
import { userTypes, ErrorBoundary } from 'shared'
import { useCheckRole } from 'auth'

import { PageTitle } from '../../../_metronic/layout/core'
import { UsersTable } from '../components/Users'
import { useUsers } from '../hooks/useUsers'

export const UserPage = () => {
  const { status, data: users, error, isFetching } = useUsers()

  return (
    <>
      <PageTitle>Users</PageTitle>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : status === 'error' ? (
        <span>Error: {(error as any).message}</span>
      ) : users ? (
        <UsersTable users={users} />
      ) : (
        <span>No users</span>
      )}
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin])
  return <UserPage />
}

export const UserPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
