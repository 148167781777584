import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const Step4: FC<any> = (props: any) => {
  const { values } = props
  return (
    <div className='w-100'>
      <div className='fv-row mb-10 d-flex flex-stack'>
        <h3 className='stepper-title'>Digital prospecting</h3>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field name='isDigitalProspectingActive' className='form-check-input' type='checkbox' />
        </label>
      </div>

      <div className='fv-row'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span>Objectives</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your objectives'
          ></i>
        </label>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Digital leads</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='digitalLeads'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='digitalLeads' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Connection requests sent</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='connectionRequestsSent'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='connectionRequestsSent' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Connection requests accepted</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='connectionRequestsAccepted'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='connectionRequestsAccepted' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Impressions</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='impressions'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='impressions' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Campaign clicks</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='campaignClicks'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='campaignClicks' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step4 }
