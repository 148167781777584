import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useFileManagerFolder, useUploadFileMutation } from '../hooks'
import { CreateFolderFormModalButton } from './CreateFolderFormModalButton'
import { Table } from './Table'
import { UploadFileButton } from './UploadFileButton'
import { Breadcrumbs } from './Breadcrumbs'

type FileManagerProps = {
  campaignId: number
  isCampaignManager: boolean
}
export const FileManager = (props: FileManagerProps) => {
  const [folderPath, setFolderPath] = useState('/')
  const resp = useFileManagerFolder({ campaignId: props.campaignId, folderPath })
  const uploadFileMutation = useUploadFileMutation({ campaignId: props.campaignId, folderPath })
  // TODO
  if (resp.status === 'error') {
    return <div>ERROR</div>
  }

  return (
    <div className='card' data-testid={`file-manager-card:${resp.status}`}>
      <div className='card-header border-0 pt-5'>
        <Breadcrumbs folderPath={folderPath} setFolderPath={setFolderPath} />
        <div className='card-toolbar'>
          <UploadFileButton
            onChange={(file) => {
              uploadFileMutation.mutate(file)
            }}
            disabled={resp.status !== 'success' || uploadFileMutation.isLoading}
            submitting={uploadFileMutation.isLoading}
          />
          <CreateFolderFormModalButton
            campaignId={props.campaignId}
            folderPath={folderPath}
            disabled={resp.status !== 'success'}
          />
        </div>
      </div>
      <div className='card-body'>
        {resp.status === 'success' ? (
          <Table
            getFolderResp={resp.data}
            setFolderPath={setFolderPath}
            isCampaignManager={props.isCampaignManager}
          />
        ) : (
          <div className='d-flex align-items-center justify-content-center'>
            <Spinner animation='grow' />
          </div>
        )}
      </div>
    </div>
  )
}
