import * as Yup from 'yup'

const folderPathField = Yup.string()
  .matches(/^\/([A-Za-z0-9 \/\-]+\/)*$/)
  .required()
//
export const getFolderReqSchema = Yup.object({
  campaignId: Yup.number().positive().required(),
  folderPath: folderPathField, // root is `/`, subfolders must always end in trailing slash
})
export type GetFolderReq = Yup.InferType<typeof getFolderReqSchema>

export const fileManagerFileSchema = Yup.object({
  name: Yup.string().required(),
  url: Yup.string().required(),
})
export const fileManagerFolderSchema = Yup.object({
  name: Yup.string().required(),
  folderPath: folderPathField,
})
export const getFolderRespSchema = Yup.object({
  campaignId: Yup.number().positive().required(),
  folderPath: folderPathField, // root is `/`, subfolders must always end in trailing slash
  expires: Yup.number().required(), // unix timestamp for when signed urls and form expire
  files: Yup.array().of(fileManagerFileSchema).required(),
  folders: Yup.array().of(fileManagerFolderSchema).required(),

  // S3.PresignedPost
  uploadFileForm: Yup.object({
    url: Yup.string().required(),
    fields: Yup.object({}).required(),
  }).required(), // TODO: form fields for uploading file to S3.
})
export type GetFolderResp = Yup.InferType<typeof getFolderRespSchema>
export type FileManagerFile = Yup.InferType<typeof fileManagerFileSchema>
export type FileManagerFolder = Yup.InferType<typeof fileManagerFolderSchema>

export const createFolderReqSchema = Yup.object({
  campaignId: Yup.number().positive().required(),
  baseFolderPath: folderPathField,
  folderName: Yup.string()
    .matches(/^[A-Za-z0-9 \-]+$/, {
      message: 'folder names may only contain letters, numbers, spaces, and -',
    })
    .required(),
})
export type CreateFolderReq = Yup.InferType<typeof createFolderReqSchema>

export const createFolderRespSchema = Yup.object({
  campaignId: Yup.number().positive().required(),
  folderPath: Yup.string().min(1).required(), // path to folder within namespace with trailing and leading slaashed (e.g. `/my-folder/`)
})
export type CreateFolderResp = Yup.InferType<typeof createFolderRespSchema>

export const deleteFolderReqSchema = Yup.object({
  campaignId: Yup.number().positive().required(),
  folderPath: folderPathField, // path to folder within namespace with trailing and leading slaashed (e.g. `/my-folder/`)
})
export type DeleteFolderReq = Yup.InferType<typeof deleteFolderReqSchema>

export const deleteFolderRespSchema = Yup.object({})
export type DeleteFolderResp = Yup.InferType<typeof deleteFolderRespSchema>

export const deleteFileReqSchema = Yup.object({
  campaignId: Yup.number().positive().required(),
  folderPath: folderPathField, // path to folder within namespace with trailing and leading slaashed (e.g. `/my-folder/`)
  name: Yup.string().min(1).required(),
})
export type DeleteFileReq = Yup.InferType<typeof deleteFileReqSchema>

export const deleteFileResp = Yup.object({})
export type DeleteFileResp = Yup.InferType<typeof deleteFileResp>
