import * as Yup from 'yup'
import { Client } from '../Client'

const inits: Client = {
  name: '',
  website: '',
  logo: undefined,
  active: true,
}

const createClientSchema = Yup.object({
  name: Yup.string().required().label('Client name'),
})

export { createClientSchema, inits }
