export enum AccountType {
  NetNew = 'Net New',
  TargetAccount = 'Target Account',
}

export interface IntentReportConfiguration {
  id: number
  provider: string
  accountType: AccountType
  configuration: {
    reportId: string
  }
  createdAt?: Date
  updatedAt?: Date
}
