import React from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import { Formik } from 'formik'
import { editUserProfileSchema, EditUserProfileDTO, IUser } from 'auth'
import { Spinner } from 'react-bootstrap'
import { Client } from 'client'
import { ICampaign } from 'campaign'
import { ErrorBoundary } from 'shared'
import { useUser } from '../hooks/useUser'
import { UserProfileEditForm } from '../components/UserProfileEditForm'
import { useUserProfileMutation } from '../hooks/useUserProfileMutation'
import { UseMutationResult, UseQueryResult } from 'react-query'

type EditUserProfileProps = {
  query: UseQueryResult
  mutation: UseMutationResult<any, any, any, any>
}

export const EditUserProfile: React.FC<EditUserProfileProps> = ({
  query,
  mutation,
}: EditUserProfileProps) => {
  const user: IUser = (query as any)?.data
  const initValues: EditUserProfileDTO = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    jobTitle: user?.jobTitle,
    email: user?.email,
    clients: user?.clients.map((client: Client) => client.id),
    campaigns: user?.campaigns.map((campaign: ICampaign) => campaign.id),
    identityId: user?.identityId,
  }

  return (
    <>
      <PageTitle>Edit your profile</PageTitle>
      {query.isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : query.status === 'error' ? (
        <span>Error: {(query.error as any).message}</span>
      ) : (
        <div className='card'>
          <Formik
            initialValues={initValues}
            validationSchema={editUserProfileSchema}
            onSubmit={async (values: any) => {
              await mutation.mutateAsync(values as any)
            }}
          >
            {({ isSubmitting }) => <UserProfileEditForm isSubmitting={isSubmitting} />}
          </Formik>
        </div>
      )}
    </>
  )
}

const EditUserProfileController: React.FC = () => {
  const query: UseQueryResult = useUser()
  const mutation: UseMutationResult<any, any, any, any> = useUserProfileMutation()

  return <EditUserProfile query={query} mutation={mutation} />
}

export const EditUserProfilePageRouteHandler = () => (
  <ErrorBoundary>
    <EditUserProfileController />
  </ErrorBoundary>
)
