import dayjs from 'dayjs'
import * as Yup from 'yup'

import { ObjectiveResult } from '../Objective'

const inits: ObjectiveResult = {
  startDate: dayjs().startOf('week').format('YYYY-MM-DD'),
  endDate: dayjs().startOf('week').add(7, 'day').format('YYYY-MM-DD'),
  intents: 0,
  companiesDiscovered: 0,
  contactsDiscovered: 0,
  totalCompaniesDiscovered: undefined,
  totalIntents: undefined,
  totalContactsDiscovered: undefined,
  telesaleHours: 0,
  meetingBooked: 0,
  targetPipelineValue: 0,
  dealRegDone: 0,
  totalTelesaleHours: undefined,
  totalMeetingBooked: undefined,
  totalTargetPipelineValue: undefined,
  totalDealRegDone: undefined,
  digitalLeads: 0,
  connectionRequestsSent: 0,
  connectionRequestsAccepted: 0,
  impressions: 0,
  campaignClicks: 0,
  totalDigitalLeads: undefined,
  totalConnectionRequestsSent: undefined,
  totalConnectionRequestsAccepted: undefined,
  totalImpressions: undefined,
  totalCampaignClicks: undefined,
}

const createOjectiveResultSchema = Yup.object({
  startDate: Yup.date().required().label('Start date'),
  endDate: Yup.date().required().label('End date'),
  intents: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Intents'),
  totalIntents: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total intents'),
  companiesDiscovered: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Companies discovered'),
  totalCompaniesDiscovered: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Companies discovered'),
  contactsDiscovered: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Contacts discovered'),
  totalContactsDiscovered: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Contacts discovered'),
  telesaleHours: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Telesales hours'),
  meetingBooked: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Meetings Booked'),
  targetPipelineValue: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Target pipeline value'),
  dealRegDone: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Deal reg done'),
  digitalLeads: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Digital leads'),
  connectionRequestsSent: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Connection requests sent'),
  connectionRequestsAccepted: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Connection requests accepted'),
  impressions: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Impressions'),
  campaignClicks: Yup.number()
    .required()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Campaign clicks'),
  totalTelesaleHours: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total telesale hours'),
  totalMeetingBooked: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total meeting booked'),
  totalTargetPipelineValue: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total target pipeline'),
  totalDealRegDone: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total deal reg done'),
  totalDigitalLeads: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total digital leads'),
  totalConnectionRequestsSent: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total connection requests sent'),
  totalConnectionRequestsAccepted: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total connection requests accepted'),
  totalImpressions: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total impressions'),
  totalCampaignClicks: Yup.number()
    .moreThan(-1, 'Please enter a valid value')
    .integer()
    .label('Total campaign clicks'),
})

export { createOjectiveResultSchema, inits }
