import { FC } from 'react'
import { useQueryClient } from 'react-query'
import { Link } from 'react-router-dom'
import { useUserRole } from 'auth'
import { CDropdown, userTypes } from 'shared'

import { ICampaign, CampaignStatus } from '../../models/Campaign'
import { CampaignService } from '../../services'

interface Props {
  campaign: ICampaign
}

export const CampaignCard: FC<Props> = (props: Props) => {
  const { campaign } = props
  const role: any = useUserRole()
  const queryClient = useQueryClient()

  return (
    <div className='col-md-6 col-xl-4'>
      <div className='card border border-2 border-gray-300 border-hover'>
        <div className='card-header border-0 pt-9'>
          <div className='card-title m-0'>{campaign?.client?.name}</div>

          <div className='card-toolbar'>
            {campaign?.status !== CampaignStatus.InProgress ? (
              <span className='badge badge-light-success fw-bolder me-auto px-4 py-3'>
                {campaign?.status}
              </span>
            ) : (
              <span className='badge badge-light-primary fw-bolder me-auto px-4 py-3'>
                {campaign?.status}
              </span>
            )}
          </div>
          {(role === userTypes.Admin ||
            role == userTypes.ClientManager ||
            role == userTypes.CampaignManager) && (
            <div className='card-toolbar'>
              <CDropdown
                items={[
                  {
                    name: 'Edit Campaign',
                    link: `/campaign/edit/${campaign.id}`,
                  },
                  {
                    name: 'Delete Campaign',
                    action: CampaignService.deleteCampaign,
                    params: {
                      id: campaign.id,
                    },
                    alertMessage: `Are you sure you want to delete the campaign?`,
                    successMessage: `You have successfully deleted the campaign.`,
                    callback: () => {
                      // queryClient.setQueryData(['client-campaign', campaign.client.id], (oldData: any) => {
                      //     const index = oldData.findIndex((d: any) => d.id == campaign.id)
                      //     oldData.splice(index,1)
                      //     return oldData
                      // })

                      queryClient.invalidateQueries(['campaign', campaign.id])
                      queryClient.refetchQueries(['client-campaign', campaign.client.id])
                    },
                  },
                ]}
              />
            </div>
          )}
        </div>
        <Link type='button' to={`/campaign/view/${campaign.id}`}>
          <div className='card-body p-9'>
            <div className='fs-3 fw-bolder text-dark'>{campaign.name}</div>
            <p className='text-gray-400 fw-bold fs-5 mt-1 mb-7'>{campaign.description}</p>
            <div className='d-flex flex-wrap mb-5'>
              {campaign?.startDate && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bolder'>{campaign?.formattedStartDate}</div>
                  <div className='fw-bold text-gray-400'>Start Date</div>
                </div>
              )}
              {campaign?.formattedEndDate && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bolder'>{campaign?.formattedEndDate}</div>
                  <div className='fw-bold text-gray-400'>End Date</div>
                </div>
              )}
              {!!campaign.objective?.companiesDiscovered && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3 me-7 '>
                  <div className='fs-6 text-gray-800 fw-bolder'>
                    {campaign.objective?.companiesDiscovered}
                  </div>
                  <div className='fw-bold text-gray-400'>
                    Companies <br /> to be discovered
                  </div>
                </div>
              )}
              {!!campaign.objective?.meetingBooked && (
                <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3'>
                  <div className='fs-6 text-gray-800 fw-bolder'>
                    {campaign.objective?.meetingBooked}
                  </div>
                  <div className='fw-bold text-gray-400'>
                    Meetings <br /> to be booked
                  </div>
                </div>
              )}
            </div>
          </div>
        </Link>
      </div>
    </div>
  )
}
