import { useState } from 'react'
import { createContext, ReactChild, useCallback, useContext, useReducer } from 'react'

type INotificaiton = {
  active: boolean
  type: string
  message: string
}
export type TNotificationContext = {
  notification: INotificaiton | undefined
  setNotification: Function
}

export const NotificationContext = createContext<TNotificationContext>({
  notification: {
    active: false,
    type: '',
    message: '',
  },
  setNotification: () => {},
})

export const useNotificationContext = () => {
  const context = useContext(NotificationContext)
  if (context === undefined) {
    throw new Error('`useNotification` must be within a `NotificationProvider`')
  }
  return context
}

export const NotificationProvider = ({ children }: { children: ReactChild }) => {
  const [notification, setNotification] = useState()

  const contextValue = {
    notification,
    setNotification: useCallback((notification) => setNotification(notification), []),
  }

  return (
    <NotificationContext.Provider value={contextValue}>{children}</NotificationContext.Provider>
  )
}
