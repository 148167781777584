import * as Yup from 'yup'
import { Link, useHistory } from 'react-router-dom'
import { useMutation } from 'react-query'
import { ErrorMessage, Field, Formik, Form } from 'formik'

import { AuthLayout } from '../components/AuthLayout'
import { UserService } from '../services/user'

const initialValues = {
  email: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function ForgotPassword() {
  const history = useHistory()
  const mutation = useMutation(
    (values: any) => {
      return UserService.forgotPassword({
        email: values.email,
      })
    },
    {
      onSuccess: (data) => {
        history.push('/auth/confirm-forgot-password', data)
      },
    }
  )

  const onSubmit = async (values: any) => {
    await mutation.mutateAsync(values)
  }

  return (
    <AuthLayout>
      <Formik
        validationSchema={forgotPasswordSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ values }) => (
          <Form className='form w-100'>
            <div className='text-center mb-10'>
              <div className='text-dark fw-bold fs-4'> Reset your password</div>
            </div>

            {/* begin::Title */}
            {mutation.isError === true && (
              <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                  {(mutation as any)?.error.response?.data}
                </div>
              </div>
            )}

            <div className='fv-row mb-10'>
              <label className='d-flex align-items-center fs-6 fw-bolder text-gray-900 mb-2'>
                <span className='required'>Email</span>
              </label>

              <Field
                type='text'
                className='form-control form-control-lg form-control-solid'
                name='email'
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='email' />
              </div>
            </div>

            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
              <button
                type='submit'
                id='kt_password_reset_submit'
                className='btn btn-lg btn-primary fw-bolder me-4'
                disabled={mutation.isLoading}
              >
                {mutation.isLoading && (
                  <span className='spinner-border spinner-border-sm align-middle mx-2' />
                )}
                <span className='indicator-label'>Submit</span>
              </button>
              <Link to='/auth/login'>
                <button
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className='btn btn-lg btn-light-primary fw-bolder'
                >
                  Cancel
                </button>
              </Link>{' '}
            </div>
            {/* end::Form group */}
          </Form>
        )}
      </Formik>
    </AuthLayout>
  )
}
