import { FC } from 'react'
import { NavDropdown } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

export const DropdownLink: FC<any> = (props: any) => {
  const { items, title } = props
  const history = useHistory()
  return (
    <NavDropdown title={title}>
      {items.map((item: any, index: number) => (
        <NavDropdown.Item className='p-3' key={index} onClick={() => history.push(item.link)}>
          <span className='text-dark fw-bold text-capitalize menu-link'>{item.name}</span>
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  )
}
