export enum userTypes {
  Admin = 0,
  ClientManager = 1,
  CampaignManager = 2,
  ClientViewer = 3,
  ClientUser = 4,
  CampaignUser = 5,
}

export enum Metrics {
  intentReports = 'Intent reports',
  campaniesDiscovered = 'Companies discovered',
  contactsDiscovered = 'Contacts discovered',
  telesaleHours = 'Telesale hours',
  meetingsBooked = 'Meetings booked',
  targetPipelineValue = 'Target pipeline',
  dealRegDone = 'Deal reg done',
  digitalLeads = 'Digital leads',
  connectionRequestsSent = 'Connection requests sent',
  connectionRequestsAccepted = 'Connection requests accepted',
  impressions = 'Impressions',
  campaignClicks = 'Campaign clicks',
}

export enum UserStatus {
  'FORCE_CHANGE_PASSWORD' = 'INVITATION EMAIL SENT',
  'UNCONFIRMED' = 'USER CREATED BUT UNCONFIRMED',
  'CONFIRMED' = 'EMAIL VERIFIED',
  'EXTERNAL_PROVIDER' = 'SSO VERIFIED',
  'ARCHIVED' = 'NO LONGER ACTIVE',
  'UNKNOWN' = 'UNKNOWN',
  'RESET_REQUIRED' = 'USER CONFIRMED BUT RESET REQUIRED',
}
