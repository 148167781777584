import React from 'react'

import { Form } from 'formik'
import { Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'
import { CSelect as CustomSelect } from 'shared'
import { useClients } from 'client'
import { useClientCampaign } from 'campaign'
import { userTypeOptions } from 'auth'

const UserForm: React.FC<any> = (props: any) => {
  const { mutation, values } = props
  const clients = useClients()
  const campaigns = useClientCampaign(values?.clients[0])
  const campaignOptions = campaigns?.data
    ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
    .map((d: any) => {
      return {
        value: d.id,
        label: d.name,
      }
    })

  const clientOptions = clients?.data
    ?.sort((a: any, b: any) => a.name.localeCompare(b.name))
    .map((d: any) => {
      return {
        value: d.id,
        label: d.name,
      }
    })

  return (
    <Form className='form' noValidate id='kt_create_account_form'>
      <div className='card-body fv-row mb-10'>
        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>User type</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify user type'
            ></i>
          </label>
          <Field
            name='userType'
            component={CustomSelect}
            className=' form-control form-control-lg form-control-solid'
            options={userTypeOptions}
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='userType' />
          </div>
        </div>

        <div className='fv-row mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Client</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify your unique campaign name'
            ></i>
          </label>
          {clients.isFetching ? (
            <div className='d-flex align-items-center justify-content-center'>
              <Spinner animation='border'></Spinner>
            </div>
          ) : clients.status === 'error' ? (
            <span>Error: {(clients.error as any).message}</span>
          ) : (
            <>
              <Field
                name='clients'
                component={CustomSelect}
                isMulti={true}
                className=' form-control form-control-lg form-control-solid'
                options={clientOptions}
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='clients' />
              </div>
            </>
          )}
        </div>

        {values.userType === 'CampaignUser' && values.clients[0] && (
          <div className='fv-row mb-10'>
            <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
              <span>Campaign</span>
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Select campaign to be assigned'
              ></i>
            </label>
            {campaigns.isFetching ? (
              <div className='d-flex align-items-center justify-content-center'>
                <Spinner animation='border'></Spinner>
              </div>
            ) : campaigns.status === 'error' ? (
              <span>Error: {(campaigns.error as any).message}</span>
            ) : (
              <>
                <Field
                  name='campaigns'
                  component={CustomSelect}
                  isMulti={true}
                  className=' form-control form-control-lg form-control-solid'
                  options={campaignOptions}
                />
                <div className='text-danger mt-2'>
                  <ErrorMessage name='campaigns' />
                </div>
              </>
            )}
          </div>
        )}

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Email</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a user for the user'
            ></i>
          </label>

          <Field
            type='email'
            className='form-control form-control-lg form-control-solid'
            name='email'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='email' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>First Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a first name for the user'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='firstName'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='firstName' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Last Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a last name for the user'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='lastName'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='lastName' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Job Title</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a job title for the user'
            ></i>
          </label>
          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='jobTitle'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='jobTitle' />
          </div>
        </div>

        <button type='submit' className='btn btn-primary' disabled={mutation.isLoading}>
          {mutation.isLoading && (
            <span className='spinner-border spinner-border-sm align-middle mx-2' />
          )}
          Submit
        </button>
        <Link to='/user' className='btn btn-secondary mx-10'>
          Cancel
        </Link>
      </div>
    </Form>
  )
}

export { UserForm }
