import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Formik } from 'formik'
import { useNotificationContext, userTypes, ErrorBoundary } from 'shared'
import { useCheckRole } from 'auth'

import { PageTitle } from '../../../_metronic/layout/core'
import { ClientService } from '../services'
import { Client } from '../Client'
import { inits, createClientSchema } from '../components/CreateClientHelper'
import { ClientForm } from '../components/ClientForm'
import { useClients } from '../hooks'

const CreateClientPage = () => {
  useClients()
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const history = useHistory()
  const mutation = useMutation((client: Client) => ClientService.createClient(client), {
    onSuccess: () => {
      setNotification({
        active: true,
        message: ' You have successfully created the client.',
        type: 'success',
      })
      queryClient.invalidateQueries('clients')
    },
    onError: (e: any) => {
      setNotification({
        active: true,
        message: e.message,
        type: 'danger',
      })
    },
  })

  return (
    <>
      <PageTitle>Create Client</PageTitle>
      <div className='card'>
        <Formik
          initialValues={inits}
          validationSchema={createClientSchema}
          onSubmit={async (values, actions) => {
            await mutation.mutateAsync(values as any)
            actions.resetForm()
            history.push(`/client`)
          }}
        >
          {(props) => <ClientForm form={props} mutation={mutation} />}
        </Formik>
      </div>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager])
  return <CreateClientPage />
}

export const CreateClientPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
