import { Field, ErrorMessage, FieldArray } from 'formik'
import { useAllDomainValues, CSelect as CustomSelect } from 'shared'

import { CreateCampaignRequest } from '../../../models/Campaign'

type StepICPProps = {
  values: CreateCampaignRequest
}
export const StepICP = (props: StepICPProps) => {
  const domainValuesResp = useAllDomainValues()

  // TODO: handle this properly
  if (domainValuesResp.status === 'error') {
    return <div>ERROR {domainValuesResp.error}</div>
  }

  return (
    <div className='w-xl-600px'>
      <h3 className='fv-row mt-15 mb-10'>ICP</h3>
      <FieldArray name='icps'>
        {() => (
          <div>
            {props.values.icps?.map((icp, index) => (
              <div key={index}>
                <div className='fv-row me-10 flex-fill'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span className='required'>ICP Name</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify your ICP name'
                    ></i>
                  </label>
                  <Field
                    type='text'
                    className='form-control form-control-lg form-control-solid'
                    name={`icps.${index}.name`}
                    placeholder=''
                  />
                  <div className='text-danger mt-2'>
                    <ErrorMessage name={`icps.${index}.name`} />
                  </div>
                </div>

                <div className='fv-row me-10 flex-fill'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Industry</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify the Industry'
                    ></i>
                  </label>
                  {domainValuesResp.status === 'loading' || domainValuesResp.status === 'idle' ? (
                    <Field
                      name={`icps.${index}.industryId`}
                      component={CustomSelect}
                      isMulti={false}
                      options={[]}
                      disabled
                    />
                  ) : (
                    <Field
                      name={`icps.${index}.industryId`}
                      component={CustomSelect}
                      isMulti={false}
                      isClearable
                      options={domainValuesResp.data.industries.map((d) => ({
                        label: d.name,
                        value: d.id,
                      }))}
                    />
                  )}
                  <div className='text-danger mt-2'>
                    <ErrorMessage name={`icps.${index}.industryId`} />
                  </div>
                </div>

                <div className='fv-row me-10 flex-fill'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Revenue Range</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify the Revenue Range'
                    ></i>
                  </label>
                  {domainValuesResp.status === 'loading' || domainValuesResp.status === 'idle' ? (
                    <Field
                      name={`icps.${index}.revenueRangeId`}
                      component={CustomSelect}
                      isMulti={false}
                      options={[]}
                      disabled
                    />
                  ) : (
                    <Field
                      name={`icps.${index}.revenueRangeId`}
                      component={CustomSelect}
                      isMulti={false}
                      isClearable
                      options={domainValuesResp.data.revenueRanges.map((d) => ({
                        label: d.name,
                        value: d.id,
                      }))}
                    />
                  )}
                  <div className='text-danger mt-2'>
                    <ErrorMessage name={`icps.${index}.reveueRangeId`} />
                  </div>
                </div>

                <div className='fv-row me-10 flex-fill'>
                  <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                    <span>Employee Range</span>
                    <i
                      className='fas fa-exclamation-circle ms-2 fs-7'
                      data-bs-toggle='tooltip'
                      title='Specify the EmployeeRange'
                    ></i>
                  </label>
                  {domainValuesResp.status === 'loading' || domainValuesResp.status === 'idle' ? (
                    <Field
                      name={`icps.${index}.employeeRangeId`}
                      component={CustomSelect}
                      isMulti={false}
                      options={[]}
                      disabled
                    />
                  ) : (
                    <Field
                      name={`icps.${index}.employeeRangeId`}
                      component={CustomSelect}
                      isMulti={false}
                      isClearable
                      options={domainValuesResp.data.employeeRanges.map((d) => ({
                        label: d.name,
                        value: d.id,
                      }))}
                    />
                  )}
                  <div className='text-danger mt-2'>
                    <ErrorMessage name={`icps.${index}.employeeRangeId`} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </FieldArray>
    </div>
  )
}
