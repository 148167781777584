import { FC } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams } from 'react-router'
import { useNotificationContext } from 'shared'

import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'
import { StepICP } from './steps/StepICP'
import { Step6 } from './steps/Step6'

import { KTSVG } from '../../../../_metronic/helpers'
import { Formik, Form, FormikValues } from 'formik'
import { CampaignService } from '../../services'
import {
  CreateCampaignRequest,
  createCampaignRequestSchema,
  CampaignStatus,
} from '../../models/Campaign'
import { useClientCampaign } from '../../hooks'
import { Stepper, useStepper, StepperContent } from './Stepper'

enum Step {
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

const stepSchemas = {
  [Step.One]: createCampaignRequestSchema.pick(['name', 'startDate', 'lookerClientId']),
  [Step.Two]: createCampaignRequestSchema.pick([
    'intents',
    'companiesDiscovered',
    'contactsDiscovered',
  ]),
  [Step.Three]: createCampaignRequestSchema.pick([
    'telesaleHours',
    'meetingBooked',
    'targetPipelineValue',
    'dealRegDone',
  ]),
  [Step.Four]: createCampaignRequestSchema.pick([
    'digitalLeads',
    'connectionRequestsAccepted',
    'connectionRequestsSent',
    'impressions',
    'campaignClicks',
  ]),
  [Step.Five]: createCampaignRequestSchema.pick(['icps']),
  [Step.Six]: createCampaignRequestSchema.pick(['lookerClientId', 'intentReportConfigurations']),
}

type CampaignFormProps =
  | {
      campaignId: number
      type: 'edit'
      campaign: CreateCampaignRequest
    }
  | { type: 'create' }

const CampaignForm: FC<CampaignFormProps> = (props: CampaignFormProps) => {
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const history = useHistory()
  const params: any = useParams() // TODO: get router out of here
  const clientId = props.type === 'edit' ? props.campaign.client : Number(params?.clientId)
  useClientCampaign(clientId) // Q: why doesn't this return anything?
  const { currentStep, setCurrentStep, goNextStep, goPrevStep, isFinalStep, isFirstStep } =
    useStepper<Step>(6)
  const initValues: CreateCampaignRequest = {
    // Q: do initial values belong here or in models?
    ...{
      name: '',
      description: '',
      startDate: '',
      endDate: '',
      intents: 0,
      companiesDiscovered: 0,
      contactsDiscovered: 0,
      telesaleHours: 0,
      meetingBooked: 0,
      targetPipelineValue: 0,
      dealRegDone: 0,
      digitalLeads: 0,
      connectionRequestsSent: 0,
      connectionRequestsAccepted: 0,
      impressions: 0,
      campaignClicks: 0,
      isDataIntelligenceActive: true,
      isTeleProspectingActive: true,
      isDigitalProspectingActive: true,
      lookerClientId: null,
      status: CampaignStatus.InProgress,
      intentReportConfigurations: [],
      icps: [{ name: '', industryId: null, revenueRangeId: null, employeeRangeId: null }],
    },
    ...(props.type === 'edit' ? props.campaign : {}),
    client: clientId,
  }
  const mutation = useMutation(
    (camp: CreateCampaignRequest) => {
      const campaignDTO: any = {}
      for (const [key, value] of Object.entries(camp)) {
        campaignDTO[key] = value === '' ? null : value
      }

      return props.type === 'edit'
        ? CampaignService.editCampaign(campaignDTO, props.campaignId)
        : CampaignService.createCampaigns(campaignDTO)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: `You have successfully ${
            props.type === 'edit' ? 'edited' : 'created'
          } the campaign.`,
          type: 'success',
        })
        if (props.type === 'edit') queryClient.invalidateQueries(['campaign', props.campaignId])
        queryClient.invalidateQueries(['client-campaign', clientId])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.message,
          type: 'danger',
        })
      },
    }
  )

  const submitStep = async (values: CreateCampaignRequest, actions: FormikValues) => {
    if (!isFinalStep) {
      goNextStep()
    } else {
      setCurrentStep(1)
      await mutation.mutateAsync(values as any)
      actions.resetForm()
      history.goBack() // TODO: navigate to a specific route, don't rely on history here.
    }
  }

  return (
    <div className={`card`}>
      <div className=''>
        <div className='card-content'>
          <div className='d-flex flex-wrap justify-content-center card-body py-lg-10 px-lg-10'>
            <div className='d-flex justify-content-center justify-content-xl-start w-xl-300px'>
              <Stepper
                currentStep={currentStep}
                numSteps={6}
                steps={[
                  { step: Step.One, title: 'Basic Details' },
                  { step: Step.Two, title: 'Data and Intelligence' },
                  { step: Step.Three, title: 'Teleprospecting' },
                  { step: Step.Four, title: 'Digital prospecting' },
                  { step: Step.Five, title: 'ICP' },
                  { step: Step.Six, title: 'Integration' },
                ]}
              />
            </div>
            <div className='d-flex flex-grow-1 flex-center bg-white rounded'>
              <Formik
                validationSchema={stepSchemas[currentStep as Step]}
                initialValues={initValues}
                onSubmit={submitStep}
              >
                {({ values, setFieldValue }) => (
                  <Form className='form' noValidate id='kt_create_account_form'>
                    <StepperContent step={Step.One} currentStep={currentStep}>
                      <Step1 type={props.type} values={values} />
                    </StepperContent>

                    <StepperContent step={Step.Two} currentStep={currentStep}>
                      <Step2 values={values} />
                    </StepperContent>

                    <StepperContent step={Step.Three} currentStep={currentStep}>
                      <Step3 values={values} />
                    </StepperContent>

                    <StepperContent step={Step.Four} currentStep={currentStep}>
                      <Step4 values={values} />
                    </StepperContent>

                    <StepperContent step={Step.Five} currentStep={currentStep}>
                      <StepICP values={values} />
                    </StepperContent>

                    <StepperContent step={Step.Six} currentStep={currentStep}>
                      <Step6 type={props.type} values={values} setFieldValue={setFieldValue} />
                    </StepperContent>

                    <div className='d-flex flex-stack pt-10'>
                      <div className='mr-2'>
                        {isFirstStep ? null : (
                          <button
                            onClick={goPrevStep}
                            type='button'
                            className='btn btn-lg btn-light-primary me-3'
                            data-kt-stepper-action='previous'
                          >
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr063.svg'
                              className='svg-icon-4 me-1'
                            />
                            Back
                          </button>
                        )}
                      </div>

                      <div>
                        <button
                          type='submit'
                          className='btn btn-lg btn-primary me-3'
                          disabled={mutation.isLoading}
                        >
                          {mutation.isLoading && (
                            <span className='spinner-border spinner-border-sm align-middle mx-2' />
                          )}
                          <span className='indicator-label'>
                            {isFinalStep ? 'Submit' : 'Continue'}
                            <KTSVG
                              path='/media/icons/duotune/arrows/arr064.svg'
                              className='svg-icon-3 ms-2 me-0'
                            />
                          </span>
                        </button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { CampaignForm }
