import React from 'react'
import { Button } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useNotificationContext } from 'shared'

import { ContactService } from '../services'

export const CleanContactButton: React.FC<any> = ({ lookerClientId }) => {
  const { setNotification } = useNotificationContext()
  const mutation = useMutation(
    () => {
      return ContactService.cleanContacts(lookerClientId)
    },
    {
      onSuccess: (data: any) => {
        setNotification({
          active: true,
          message: data,
          type: 'success',
        })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  const onCleanContacts = () => {
    mutation.mutate()
  }

  return (
    <Button onClick={onCleanContacts} className='btn btn-sm btn-primary me-3 float-right'>
      {mutation.isLoading && (
        <span className='spinner-border spinner-border-sm align-middle mx-2' />
      )}{' '}
      Remove Duplicates
    </Button>
  )
}
