import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useParams, useLocation } from 'react-router'
import { useNotificationContext, ErrorBoundary } from 'shared'
import { useCheckRole } from 'auth'
import { useCampaign } from 'campaign'

import { PageTitle } from '../../../_metronic/layout/core'
import { ContactService } from '../services'
import { userTypes } from 'shared'
import { ContactReviewTable } from '../components/ContactReviewTable'

type Props = { campaignId: number }
export const ContactReviewPage = (props: Props) => {
  const { setNotification } = useNotificationContext()
  const history = useHistory()
  const location: any = useLocation()
  const queryClient = useQueryClient()
  const { data: campaign } = useCampaign(props.campaignId)

  const mutation = useMutation(
    (contact: any) => {
      contact.campaignId = props.campaignId
      contact.lookerClientId = campaign?.lookerClientId
      return ContactService.processContact(contact)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'You have successfully uploaded contacts.',
          type: 'success',
        })
        history.push(`/campaign/${props.campaignId}/contact`)
        queryClient.refetchQueries(['contacts', props.campaignId])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  return (
    <>
      <PageTitle>Upload Contacts</PageTitle>
      <div className='card'>
        <ContactReviewTable
          contacts={location?.state?.data}
          payload={location?.state?.payload}
          mutation={mutation}
        />
      </div>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  const params: any = useParams()
  const campaignId = Number(params?.id)
  return <ContactReviewPage campaignId={campaignId} />
}

export const ContactReviewPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
