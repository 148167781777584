import BTable from 'react-bootstrap/Table'
import clsx from 'clsx'
import { ConfirmationModal, ConfirmationModalRenderButtonProps } from 'shared'

import { GetFolderResp, FileManagerFile, FileManagerFolder } from '../schemas'
import { useDeleteFileMutation, useDeleteFolderMutation } from '../hooks'
import './Table.scss'

type TableProps = {
  setFolderPath: (folderPath: string) => void
  getFolderResp: GetFolderResp
  isCampaignManager?: boolean
}
export const Table = (props: TableProps) => {
  return (
    <BTable bordered className='flushed table-hover file-manager__Table'>
      <tbody>
        {props.getFolderResp.folders.map((folder) => (
          <FolderRow {...props} folder={folder} key={folder.folderPath} />
        ))}
        {props.getFolderResp.files.map((file) => (
          <FileRow {...props} file={file} key={file.url} />
        ))}
      </tbody>
    </BTable>
  )
}

const FolderRow = (
  props: TableProps & {
    folder: FileManagerFolder
  }
) => {
  const deleteMutation = useDeleteFolderMutation({ campaignId: props.getFolderResp.campaignId })
  return (
    <tr
      key={props.folder.name}
      style={{
        opacity: deleteMutation.isLoading ? '0.5' : '1.0',
      }}
    >
      <td style={{ width: '2em' }}>
        <i className='bi bi-folder2'></i>
      </td>
      <td>
        <button
          type='button'
          className='btn btn-link btn-sm'
          onClick={(e) => {
            e.preventDefault()
            props.setFolderPath(props.folder.folderPath)
          }}
          style={{ padding: 0 }}
        >
          {props.folder.name}
        </button>
      </td>
      {props.isCampaignManager ? (
        <td style={{ width: '3em' }}>
          <ConfirmationModal
            alertMessage={`Are you sure you want to delete the ${props.folder.name} folder? All of the contents will be deleted.`}
            action={() => {
              deleteMutation.mutateAsync({
                campaignId: props.getFolderResp.campaignId,
                folderPath: props.folder.folderPath,
              })
            }}
            renderButton={(rp) => <TrashButton {...rp} loading={deleteMutation.isLoading} />}
          />
        </td>
      ) : null}
    </tr>
  )
}

const FileRow = (
  props: TableProps & {
    file: FileManagerFile
  }
) => {
  const deleteMutation = useDeleteFileMutation({ campaignId: props.getFolderResp.campaignId })
  return (
    <tr
      key={props.file.name}
      style={{
        opacity: deleteMutation.isLoading ? '0.5' : '1.0',
      }}
      data-test-key='file-row'
    >
      <td style={{ width: '2em' }}>
        <i className='bi bi-file-earmark'></i>
      </td>
      <td>
        <a href={props.file.url}>{props.file.name}</a>
      </td>
      {props.isCampaignManager ? (
        <td style={{ width: '3em' }}>
          <ConfirmationModal
            alertMessage={`Are you sure you want to delete ${props.file.name}?`}
            action={() => {
              deleteMutation.mutateAsync({
                campaignId: props.getFolderResp.campaignId,
                folderPath: props.getFolderResp.folderPath,
                name: props.file.name,
              })
            }}
            renderButton={(rp) => <TrashButton {...rp} loading={deleteMutation.isLoading} />}
          />
        </td>
      ) : null}
    </tr>
  )
}

const TrashButton = (props: ConfirmationModalRenderButtonProps & { loading?: boolean }) => {
  const { loading, ...rp } = props
  return (
    <button
      {...rp}
      type='button'
      className={clsx('btn btn-link btn-sm', !loading && 'file-manager__Table--show-on-tr-hover')}
      style={{ padding: 0 }}
      disabled={loading}
      title='Delete item'
    >
      {loading ? (
        <span className='spinner-border spinner-border-sm' />
      ) : (
        <i className='bi bi-trash'></i>
      )}
    </button>
  )
}
