import { useEffect } from 'react'
import { Amplify, Auth } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { userTypes, isLocalhost, config } from 'shared'

import { useAuthContext } from '../contexts'
import { AuthActions } from '../reducers'

export const useAuthCheck = () => {
  const { dispatch } = useAuthContext()
  const redirectSignIn = isLocalhost
    ? `http://localhost:3011/auth/token`
    : `${process.env.REACT_APP_URL}/auth/token`
  const redirectSignOut = isLocalhost
    ? `http://localhost:3011/logout`
    : `${process.env.REACT_APP_URL}/logout`

  Amplify.configure({
    Auth: {
      mandatorySignIn: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      identityPoolId: config.cognito.IDENTITY_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID,
      oauth: {
        domain: config.cognito.DOMAIN,
        redirectSignIn,
        redirectSignOut,
        responseType: 'code',
        scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
      },
    },
    Storage: {
      AWSS3: {
        region: config.s3.REGION,
        bucket: config.s3.BUCKET,
        identityPoolId: config.cognito.IDENTITY_POOL_ID,
      },
    },
    API: {
      endpoints: [
        {
          name: 'client',
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
            }
          },
        },
        {
          name: 'public',
          endpoint: config.apiGateway.URL,
          region: config.apiGateway.REGION,
        },
      ],
    },
  })

  useEffect(() => {
    onLoad()
  }, [])

  async function onLoad() {
    dispatch({
      type: AuthActions.FETCH_USER_DATA_INIT,
    })

    try {
      const session = await Auth.currentSession()
      dispatch({
        type: AuthActions.FETCH_USER_DATA_SUCCESS,
        payload: { session },
      })
    } catch (error: any) {
      dispatch({
        type: AuthActions.FETCH_USER_DATA_FAILURE,
        payload: { error },
      })
    }
  }
}

export const useUserRole = () => {
  const { session }: { session: any } = useAuthContext()
  if (session && session?.accessToken?.payload['cognito:groups']) {
    const groups: string[] = session?.accessToken?.payload['cognito:groups']
    const validGroups: any = groups
      ?.map((group: any) => {
        return userTypes[group]
      })
      .filter((g: any) => g != undefined)

    const lowestRole = validGroups.length ? Math.max(...validGroups) : userTypes.ClientUser
    return lowestRole
  }
  return userTypes['ClientUser']
}

export const useCheckRole = (roles: any = []) => {
  const history = useHistory()
  const role = useUserRole()

  if (!roles.includes(role)) return history.push('/error/403')
  else return true
}
