import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { useNotificationContext, userTypes, ErrorBoundary } from 'shared'
import { Formik } from 'formik'
import { inits, createUserSchema, CreateUserDTO, useCheckRole, UserService } from 'auth'

import { PageTitle } from '../../../_metronic/layout/core'
import { useUsers } from '../hooks/useUsers'
import { UserForm } from '../components/UserForm'

export const CreateUserPage: React.FC = () => {
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const history = useHistory()
  useUsers()
  const mutation = useMutation((user: CreateUserDTO) => UserService.createUser(user), {
    onSuccess: () => {
      setNotification({
        active: true,
        message: ' You have successfully created the user.',
        type: 'success',
      })
      queryClient.invalidateQueries('users')
    },
    onError: (e: any) => {
      setNotification({
        active: true,
        message: e.response.data || e.message,
        type: 'danger',
      })
    },
  })

  return (
    <>
      <PageTitle>Create User</PageTitle>
      <div className='card'>
        <Formik
          initialValues={inits}
          validationSchema={createUserSchema}
          onSubmit={async (values, actions) => {
            await mutation.mutateAsync(values as any)
            actions.resetForm()
            history.push(`/user`)
          }}
        >
          {(props) => <UserForm form={props} mutation={mutation} values={props.values} />}
        </Formik>
      </div>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin])
  return <CreateUserPage />
}

export const CreateUserPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />{' '}
  </ErrorBoundary>
)
