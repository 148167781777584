import React, { ReactComponentElement, useState } from 'react'
import clsx from 'clsx'

type UseStepperProps = {
  numSteps: number // > 0
  currentStep: number // <= numSteps
  setCurrentStep: (step: number) => void
  goNextStep: () => void
  goPrevStep: () => void
  isFinalStep: boolean
  isFirstStep: boolean
}
export function useStepper<T extends number>(numSteps: T): UseStepperProps {
  // first step is 1
  const [currentStep, setCurrentStep] = useState(1)
  return {
    numSteps,
    currentStep,
    setCurrentStep,
    isFinalStep: currentStep === numSteps,
    isFirstStep: currentStep === 1,
    goNextStep: () => {
      if (currentStep >= numSteps) return numSteps
      setCurrentStep(currentStep + 1)
    },
    goPrevStep: () => {
      if (currentStep <= 1) return 1
      setCurrentStep(currentStep - 1)
    },
  }
}

type StepProps = {
  step: number
  title: string
  currentStep: number
  numSteps: number
}
const Step = (props: StepProps) => (
  <div
    className={clsx('stepper-item', {
      current: props.step === props.currentStep,
      completed: props.step < props.currentStep,
      pending: props.step > props.currentStep,
    })}
    data-kt-stepper-element='nav'
  >
    <div className='stepper-line w-40px'></div>

    <div className='stepper-icon w-40px h-40px'>
      <i className='stepper-check fas fa-check'></i>
      <span className='stepper-number'>{props.step}</span>
    </div>

    <div className='stepper-label'>
      <h3 className='stepper-title'>{props.title}</h3>
    </div>
  </div>
)

type StepperProps = {
  currentStep: number
  numSteps: number
  steps: Array<{ step: number; title: string }>
}
export const Stepper = (props: StepperProps) => (
  <div className='stepper stepper-pills stepper-column' id='kt_create_account_stepper'>
    <div className='stepper-nav ps-lg-10'>
      {props.steps.map((s) => (
        <Step
          key={s.step}
          currentStep={props.currentStep}
          numSteps={props.numSteps}
          step={s.step}
          title={s.title}
        />
      ))}
    </div>
  </div>
)

type StepperContentProps = {
  step: number
  currentStep: number
  children: React.ReactNode
}
export const StepperContent = (props: StepperContentProps) => {
  // display this content if the current step is selected
  if (props.step === props.currentStep) {
    return <div>{props.children}</div>
  }
  return <div style={{ display: 'none' }}>{props.children}</div>
}
