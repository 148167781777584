import { useEffect, FC } from 'react'
import { Redirect, Switch, useHistory } from 'react-router-dom'
import { Auth } from 'aws-amplify'

import { LocalStorage } from '../local-storage'
import { useAuthContext } from '../contexts'
import { AuthActions } from '../reducers'

export const Logout: FC = () => {
  const { dispatch } = useAuthContext()
  const history = useHistory()

  useEffect(() => {
    handleLogout()
  }, [])

  async function handleLogout() {
    await Auth.signOut()
    await LocalStorage.delete('session')
    dispatch({
      type: AuthActions.RESET_USER_DATA,
    })
    history && history.push('/auth/login')
  }

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
