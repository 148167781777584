import { API } from 'aws-amplify'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { useNotificationContext } from 'shared'

import {
  CreateFolderResp,
  CreateFolderReq,
  GetFolderReq,
  GetFolderResp,
  DeleteFileReq,
  DeleteFileResp,
  DeleteFolderReq,
  DeleteFolderResp,
} from './schemas'

export const useFileManagerFolder = (req: GetFolderReq) => {
  const res = useQuery(
    ['file-manager', req.campaignId, req.folderPath],
    async () => {
      const resp = await API.get(
        'client',
        `/file-manager/folder?campaignId=${req.campaignId}&folderPath=${req.folderPath}`,
        {}
      )
      return resp as GetFolderResp
    },
    {
      refetchInterval: (res) => {
        // The presigned urls and presigned post form have an expiry set by the
        // server. They will need to be refetched before the expiry to
        // function.
        if (res) {
          // refresh 1 minute before expiry
          return res.expires - Date.now() - 60000
        }
        return 540000 // default to 9 minutes
      },
    }
  )

  return res
}

export const useUploadFileMutation = (req: GetFolderReq) => {
  const queryClient = useQueryClient()
  const folderResp = useFileManagerFolder(req)
  const { setNotification } = useNotificationContext()

  const uploadFile = async (file: File) => {
    if (folderResp.status !== 'success') {
      throw new Error('uploadFile cannot be done until folder is loaded')
    }
    const fd = new FormData()
    Object.entries(folderResp.data.uploadFileForm.fields).forEach(([k, v]) => {
      fd.append(k, v as string)
    })
    fd.append('file', file)

    return fetch(folderResp.data.uploadFileForm.url, { method: 'POST', body: fd })
  }

  const mutation = useMutation(uploadFile, {
    onSuccess: () => {
      queryClient.invalidateQueries(['file-manager', req.campaignId])
      setNotification({
        active: true,
        type: 'success',
        message: 'You have successfully uploaded a file.',
      })
    },
    onError: () => {
      setNotification({
        active: true,
        type: 'danger',
        message: 'The file failed to upload.',
      })
    },
  })

  return mutation
}

export const useCreateFolderMutation = (props: { campaignId: number; onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: CreateFolderReq) => {
      const resp = await API.post('client', '/file-manager/folder', {
        body: req,
      })
      return resp as CreateFolderResp
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['file-manager', props.campaignId])
        if (props.onSuccess) {
          props.onSuccess()
        }
        setNotification({
          active: true,
          type: 'success',
          message: 'You have successfully created a folder.',
        })
      },
      onError: () => {
        setNotification({
          active: true,
          type: 'danger',
          message: 'The folder failed to create.',
        })
      },
    }
  )
}

export const useDeleteFileMutation = (props: { campaignId: number; onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: DeleteFileReq) => {
      const resp = await API.del('client', '/file-manager/file', {
        body: req,
      })
      return resp as DeleteFileResp
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['file-manager', props.campaignId])
        if (props.onSuccess) {
          props.onSuccess()
        }
        setNotification({
          active: true,
          type: 'success',
          message: 'You have successfully deleted a file.',
        })
      },
      onError: () => {
        setNotification({
          active: true,
          type: 'danger',
          message: 'The file failed to delete.',
        })
      },
    }
  )
}

export const useDeleteFolderMutation = (props: { campaignId: number; onSuccess?: () => void }) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    async (req: DeleteFolderReq) => {
      const resp = await API.del('client', '/file-manager/folder', {
        body: req,
      })
      return resp as DeleteFolderResp
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['file-manager', props.campaignId])
        if (props.onSuccess) {
          props.onSuccess()
        }
        setNotification({
          active: true,
          type: 'success',
          message: 'You have successfully deleted a folder.',
        })
      },
      onError: () => {
        setNotification({
          active: true,
          type: 'danger',
          message: 'The folder failed to delete.',
        })
      },
    }
  )
}
