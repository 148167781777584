import { API } from 'aws-amplify'

type DomainValue = { id: number; name: string }

type AllDomainValues = {
  industries: DomainValue[]
  employeeRanges: DomainValue[]
  revenueRanges: DomainValue[]
}

const listAllDomainValues = async () => {
  return API.get('client', '/domain-values', {}) as Promise<AllDomainValues>
}

export const DomainValuesService = {
  listAllDomainValues,
}
