import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { FallbackView } from '../../../_metronic/partials'

import { ConfirmForgotPassword, ConfirmPage, ForgotPassword, LoginPage, TokenPage } from 'auth'

export function PublicRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/auth/token' component={TokenPage} />
        <Route path='/auth/forgot-password' component={ForgotPassword} />
        <Route path='/auth/confirm-forgot-password' component={ConfirmForgotPassword} />
        <Route path='/auth/confirm/:username?' component={ConfirmPage} />
        <Route path='/auth/login' component={LoginPage} />

        <Redirect from='*' to='/auth/login' />
      </Switch>
    </Suspense>
  )
}
