import { useParams } from 'react-router'
import { ErrorBoundary } from 'shared'

import { CampaignPage } from 'campaign/pages/CampaignPage/CampaignPage'

import { Col, Row, Spinner } from 'react-bootstrap'
import { Contact } from '../models/Contact'
import { PersonBox } from '../components/PersonBox'
import { CompanyBox } from '../components/CompanyBox'
import { ICompany } from '../models/Company'
import { PersonDetailedBox } from '../components/PersonDetailedBox'
import { useContacts } from '../hooks/useContacts'

type Props = { campaignId: number; contactId: number }
export const ContactDetailPage = (props: Props) => {
  const { isFetching, data: contacts } = useContacts(props.campaignId)
  const contact = contacts && contacts.find((contact: Contact) => contact.id === props.contactId)
  return (
    <CampaignPage>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : (
        <Row>
          <Col sm={4}>
            <PersonBox contact={contact} />
          </Col>
          <Col sm={8}>
            <Row className='mb-5'>
              <div className='card-header border-0 pt-8 px-8 '>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Company Info</span>
                </h3>
              </div>
              <div className={`card`}>
                <div className='card-body'>
                  {contact?.company && <CompanyBox company={contact?.company as ICompany} />}
                  <div></div>
                </div>
              </div>
            </Row>
            <Row>
              <div className='card-header border-0 pt-8 px-8'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bolder fs-3 mb-1'>Contact Detailed Info</span>
                </h3>
              </div>
              <div className={`card`}>
                <div className='card-body'>
                  <PersonDetailedBox contact={contact} />
                  <div></div>
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      )}
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId: number = Number(params?.id)
  const contactId: number = Number(params?.contactId)

  return <ContactDetailPage campaignId={campaignId} contactId={contactId} />
}

export const ContactDetailPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
