import { API } from 'aws-amplify'
import { Campaign, ICampaign, CreateCampaignRequest } from '../models/Campaign'

const getCampaigns = async () => {
  const campaigns = await API.get('client', `/campaigns`, {})
  return campaigns.map((campaign: ICampaign) => new Campaign(campaign))
}

const getCampaign = async (id: number) => {
  const campaign = await API.get('client', `/campaigns/${id}`, {})
  return new Campaign(campaign)
}

const createCampaigns = async (campaign: CreateCampaignRequest) => {
  return API.post('client', `/campaigns`, {
    body: campaign,
  })
}

const editCampaign = async (campaign: CreateCampaignRequest, id: number) => {
  return API.put('client', `/campaigns/${id}`, {
    body: campaign,
  })
}

const deleteCampaign = async (id: number) => {
  return API.del('client', `/campaigns/${id}`, {})
}

export const CampaignService = {
  getCampaigns,
  getCampaign,
  createCampaigns,
  editCampaign,
  deleteCampaign,
}
