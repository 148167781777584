import React, { useState } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import { Contact } from '../models/Contact'

type PersonDetailedBoxProps = {
  contact: Contact
}
export const PersonDetailedBox: React.FC<PersonDetailedBoxProps> = (
  props: PersonDetailedBoxProps
) => {
  const { contact } = props
  const [open, setOpen] = useState(true)
  return (
    <>
      <h3
        onClick={() => setOpen(!open)}
        aria-controls='contact-collapse'
        aria-expanded={open}
        className='card-title align-items-start flex-column w-100 text-hover-primary mt-2 mb-8'
      >
        {open ? (
          <i className='bi bi-chevron-up text-hover-primary mx-2 fw-boldest'></i>
        ) : (
          <i className='bi bi-chevron-down text-hover-primary mx-2 fw-boldest'></i>
        )}
        Details
      </h3>
      <Collapse in={open}>
        <div id='contact-collapse' className='p-5'>
          <Row>
            <Col md={6}>
              {[
                {
                  label: 'First Name',
                  value: contact.firstName,
                },
                {
                  label: 'Last Name',
                  value: contact.lastName,
                },
                {
                  label: 'Job Title',
                  value: contact.jobTitle,
                },
                {
                  label: 'Job Function',
                  value: contact.jobFunction?.name,
                },
                {
                  label: 'Management Level',
                  value: contact.managementLevel?.name,
                },
                {
                  label: 'Email Address',
                  value: contact.emailAddress,
                },
                {
                  label: 'LinkedIn',
                  value: contact.linkedin,
                },
              ].map((item, index) => (
                <div key={index} className='d-flex mb-3 me-15'>
                  <span className='flex-grow-2 text-muted min-w-150px'>{item.label}</span>
                  <span className='flex-grow-1 fw-bold'>{item.value}</span>
                </div>
              ))}
            </Col>
            <Col md={6}>
              {[
                {
                  label: 'Phone Number',
                  value: contact.phoneNumber,
                },
                {
                  label: 'Mobile Phone',
                  value: contact.mobilePhone,
                },
                {
                  label: 'Email Domain',
                  value: contact.emailAddress,
                },
                {
                  label: 'City',
                  value: contact.city,
                },
                {
                  label: 'State',
                  value: contact.state,
                },
                {
                  label: 'Country',
                  value: contact.country,
                },
              ].map((item, index) => (
                <div key={index} className='d-flex mb-3 me-10'>
                  <span className='flex-grow-2 text-muted min-w-150px'>{item.label}</span>
                  <span className='flex-grow-1 fw-bold'>{item.value}</span>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      </Collapse>
    </>
  )
}
