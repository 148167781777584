import { useParams } from 'react-router'
import { Link } from 'react-router-dom'

import { CampaignPage } from 'campaign/pages/CampaignPage/CampaignPage'
import { CleanContactButton } from '../components/CleanContactButton'
import { useUserRole } from 'auth'
import { userTypes, ErrorBoundary } from 'shared'
import { useCampaign } from 'campaign/hooks'
import { ContactsTable } from '../components/Contacts'
import { Spinner } from 'react-bootstrap'
import { useContacts } from '../hooks/useContacts'

type Props = { campaignId: number }
export const ContactsPage = (props: Props) => {
  const { data: campaign } = useCampaign(props.campaignId)
  const role: any = useUserRole()

  const { isFetching, data: contacts } = useContacts(props.campaignId)
  return (
    <CampaignPage>
      <div className={`card`}>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>All Contacts</span>
          </h3>
          <div className='card-toolbar'>
            <div className='card-toolbar'>
              {(role === userTypes.Admin ||
                role === userTypes.ClientManager ||
                role === userTypes.CampaignManager) && (
                <Link to={`contact/upload`} className='btn btn-sm btn-primary me-3'>
                  Upload Contacts
                </Link>
              )}
              {(role === userTypes.Admin ||
                role === userTypes.ClientManager ||
                role === userTypes.CampaignManager) && (
                <CleanContactButton lookerClientId={campaign?.lookerClientId} />
              )}
            </div>
          </div>
        </div>
        <div className='card-body'>
          {isFetching ? (
            <div className='d-flex align-items-center justify-content-center'>
              <Spinner animation='grow'></Spinner>
            </div>
          ) : (
            <ContactsTable contacts={contacts} />
          )}
        </div>
      </div>
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)

  return <ContactsPage campaignId={campaignId} />
}

export const ContactsPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
