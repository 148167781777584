import * as Yup from 'yup'

const inits = {
  key: '',
  uploadDate: '',
  campaignId: undefined,
  accountType: '',
  csv: undefined,
}

const uploadContactSchema = Yup.object({
  uploadDate: Yup.string().required().label('Upload Date'),
  csv: Yup.string().required().label('CSV File'),
  accountType: Yup.string().required().label('Account Type'),
})

export { uploadContactSchema, inits }
