import React from 'react'

import { Form } from 'formik'
import { Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'
import { Col, Row } from 'react-bootstrap'
import { Metrics } from 'shared'

interface FieldProps {
  title: string
  text: string
  fieldName: string
  totalFieldName: string
}

const CampaignObjectiveField = ({ objective, field }: { objective: any; field: FieldProps }) => (
  <Row className='mb-10'>
    <Col>
      <span className='fw-bolder fs-6'>{field.title}</span>
    </Col>
    <Col>
      <Field
        type='number'
        className='form-control form-control-lg form-control-solid'
        name={field.fieldName}
        placeholder=''
        disabled={!objective[field.fieldName]}
      />
      <span>Number of {field.text} in specified time period</span>
      <div className='text-danger mt-2'>
        <ErrorMessage name={field.fieldName} />
      </div>
    </Col>
    <Col>
      <Field
        type='number'
        className='form-control form-control-lg form-control-solid'
        name={field.totalFieldName}
        placeholder=''
        disabled={!objective[field.fieldName]}
      />
      <span>Total {field.text} since start of campaign</span>
      <div className='text-danger mt-2'>
        <ErrorMessage name={field.totalFieldName} />
      </div>
    </Col>
  </Row>
)

const ObjectiveForm: React.FC<any> = (props: any) => {
  const { mutation, form, campaign, backLink } = props
  return (
    <Form className='form' noValidate>
      <div className='card-content'>
        <div className='card-body py-lg-10 px-lg-10'>
          <div className='row  mb-10'>
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span className='required'>Start date</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify start date of the campaign'
                ></i>
              </label>

              <Field
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='startDate'
                placeholder=''
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='startDate' />
              </div>
            </div>
            <div className='col-md-6 fv-row'>
              <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                <span>End date</span>
                <i
                  className='fas fa-exclamation-circle ms-2 fs-7'
                  data-bs-toggle='tooltip'
                  title='Specify end date of the campaign'
                ></i>
              </label>

              <Field
                type='date'
                className='form-control form-control-lg form-control-solid'
                name='endDate'
                placeholder=''
              />
              <div className='text-danger mt-2'>
                <ErrorMessage name='endDate' />
              </div>
            </div>
          </div>

          {campaign.objective?.isDataIntelligenceActive == 1 && (
            <div className='fv-row'>
              <h2 className='mt-20 mb-10'>Data and Intelligence</h2>

              {[
                {
                  title: Metrics.intentReports,
                  text: 'intent reports',
                  fieldName: 'intents',
                  totalFieldName: 'totalIntents',
                },
                {
                  title: Metrics.campaniesDiscovered,
                  text: 'companies discovered',
                  fieldName: 'companiesDiscovered',
                  totalFieldName: 'totalCompaniesDiscovered',
                },
                {
                  title: Metrics.contactsDiscovered,
                  text: 'contacts discovered',
                  fieldName: 'contactsDiscovered',
                  totalFieldName: 'totalContactsDiscovered',
                },
              ].map((field) => (
                <CampaignObjectiveField objective={campaign.objective} field={field} />
              ))}
            </div>
          )}

          {campaign.objective?.isTeleProspectingActive == 1 && (
            <div className='fv-row'>
              <h2 className='mt-20 mb-10'>Tele Prospecting</h2>

              {[
                {
                  title: Metrics.telesaleHours,
                  text: 'telesales hours',
                  fieldName: 'telesaleHours',
                  totalFieldName: 'totalTelesaleHours',
                },
                {
                  title: Metrics.meetingsBooked,
                  text: 'meetings booked',
                  fieldName: 'meetingBooked',
                  totalFieldName: 'totalMeetingBooked',
                },
                {
                  title: Metrics.targetPipelineValue,
                  text: 'target pipeline value',
                  fieldName: 'targetPipelineValue',
                  totalFieldName: 'totalTargetPipelineValue',
                },
                {
                  title: Metrics.dealRegDone,
                  text: 'deal reg done',
                  fieldName: 'dealRegDone',
                  totalFieldName: 'totalDealRegDone',
                },
              ].map((field) => (
                <CampaignObjectiveField objective={campaign.objective} field={field} />
              ))}
            </div>
          )}

          {campaign.objective?.isDigitalProspectingActive == 1 && (
            <div className='fv-row'>
              <h2 className='mt-20 mb-10'>Digital Prospecting</h2>

              {[
                {
                  title: Metrics.digitalLeads,
                  text: 'digital leads',
                  fieldName: 'digitalLeads',
                  totalFieldName: 'totalDigitalLeads',
                },
                {
                  title: Metrics.connectionRequestsSent,
                  text: 'connection requests sent',
                  fieldName: 'connectionRequestsSent',
                  totalFieldName: 'totalConnectionRequestsSent',
                },
                {
                  title: Metrics.connectionRequestsAccepted,
                  text: 'connection requests accepted',
                  fieldName: 'connectionRequestsAccepted',
                  totalFieldName: 'totalConnectionRequestsAccepted',
                },
                {
                  title: Metrics.impressions,
                  text: 'impressions',
                  fieldName: 'impressions',
                  totalFieldName: 'totalImpressions',
                },
                {
                  title: Metrics.campaignClicks,
                  text: 'campaign clicks',
                  fieldName: 'campaignClicks',
                  totalFieldName: 'totalCampaignClicks',
                },
              ].map((field) => (
                <CampaignObjectiveField objective={campaign.objective} field={field} />
              ))}
            </div>
          )}
          <button type='submit' className='btn btn-primary' disabled={mutation.isLoading}>
            {mutation.isLoading && (
              <span className='spinner-border spinner-border-sm align-middle mx-2' />
            )}
            Submit
          </button>
          <Link to={backLink} className='btn btn-secondary m-10'>
            Cancel
          </Link>
        </div>
      </div>
    </Form>
  )
}

export { ObjectiveForm }
