import { ReactElement, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

export type ConfirmationModalRenderButtonProps = { onClick: () => void }
export type ConfirmationModalProps = {
  action: () => void
  alertMessage: string | ReactElement
  renderButton: (renderProps: ConfirmationModalRenderButtonProps) => ReactElement
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
  const { alertMessage, action } = props
  const [modal, toggleModal] = useState(false)

  const onClickHanlder = () => {
    toggleModal(true)
  }

  const handleClose = () => {
    toggleModal(false)
  }

  const handleContinue = () => {
    toggleModal(false)
    action()
  }

  return (
    <>
      {alertMessage && (
        <Modal show={modal} onHide={handleClose} centered={true}>
          <Modal.Body>{alertMessage}</Modal.Body>
          <Modal.Footer>
            <Button size='sm' variant='secondary' onClick={handleClose}>
              Cancel
            </Button>
            <Button size='sm' variant='primary' onClick={handleContinue}>
              Proceed
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {props.renderButton({ onClick: onClickHanlder })}
    </>
  )
}

// SpinnerButton can be used to render a button that has a spinner while the operation is proceeding
type SpinnerButtonProps = {
  children: string
  onClick: () => void
  loading: boolean
  variant: string
}
export const SpinnerButton = (props: SpinnerButtonProps) => (
  <Button onClick={props.onClick} size='sm' variant={props.variant}>
    {props.loading && <span className='spinner-border spinner-border-sm align-middle mx-2' />}
    {props.children}
  </Button>
)
