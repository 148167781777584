/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useUser } from '../../../../app/user/hooks/useUser'

const CustomToggle = React.forwardRef((props: any, ref) => {
  const { children, onClick } = props
  return (
    <a
      href=''
      className=''
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </a>
  )
})

const HeaderUserMenu: FC = () => {
  const { data: user, isSuccess } = useUser()

  return (
    <Dropdown align={{ sm: 'end' }}>
      <Dropdown.Toggle className='p-5' as={CustomToggle}>
        <div className='symbol symbol-30px symbol-md-40px me-5 bg-success p-4 text-white'>
          {user?.avatar}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px '>
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5 bg-success p-4 text-white'>{user?.avatar}</div>

            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>{user?.name}</div>
              <div className='fs-8 d-flex align-items-center text-break'>
                {user?.userAttributes?.find((attr: any) => attr.Name == 'email')?.Value}
              </div>
            </div>
          </div>
        </div>

        <Dropdown.Divider />
        <div className='menu-item px-5'>
          <Link to='/user/edit' className='menu-link px-5'>
            Edit profile
          </Link>
        </div>
        <div className='menu-item px-5'>
          <Link to='/logout' className='menu-link px-5'>
            Logout
          </Link>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export { HeaderUserMenu }
