import { Link } from 'react-router-dom'
import { Client } from 'client'
import { useUserRole } from 'auth'
import { userTypes } from 'shared'

import { CampaignCard } from './partials/CampaignCard'
import { KTSVG } from '../../../_metronic/helpers'
import { ICampaign } from '../models/Campaign'

export const Campaigns = ({
  campaigns,
  client,
}: {
  campaigns: ICampaign[]
  client: Client | undefined
}) => {
  const role: any = useUserRole()
  return (
    <>
      <div className='d-flex flex-wrap flex-stack my-5' data-select2-id='select2-data-122-3gf7'>
        <h2 className='fs-2 fw-bold my-2'>Campaigns</h2>
        {(role === userTypes.Admin || role === userTypes.ClientManager) && (
          <div className='d-flex flex-wrap my-2'>
            <div className='me-4'>
              <Link to={`/client/${client?.id}/campaign/create`} className='btn btn-primary btn-sm'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                New Campaign
              </Link>
            </div>
          </div>
        )}
      </div>
      <div className='row g-6 g-xl-9'>
        {campaigns.map((campaign: ICampaign, key: number) => (
          <CampaignCard campaign={campaign} key={key} />
        ))}
        {campaigns.length == 0 && <h3 className='text-center my-10'>No campaigns yet</h3>}
      </div>
    </>
  )
}
