/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ConfirmationModal, SpinnerButton, Table } from 'shared'
import { useUserRole } from 'auth'

import { useDissociateContactMutation } from '../useDissociateContactMutation'
import { Contact } from '../models/Contact'
import { userTypes } from 'shared'

const AlertMessage = ({ contact }: { contact: Contact }) => {
  return (
    <div>
      <p className='fs-4'>
        Do you want to remove{' '}
        <b>
          {contact.firstName} {contact.lastName}
        </b>{' '}
        from the campaign?{' '}
      </p>
      <div className='mt-5'>
        {[
          {
            label: 'First Name',
            value: contact.firstName,
          },
          {
            label: 'Last Name',
            value: contact.lastName,
          },
          {
            label: 'Job Title',
            value: contact.jobTitle,
          },
          {
            label: 'Mobile Phone',
            value: contact.mobilePhone,
          },
          {
            label: 'Company Name',
            value: contact.company?.name,
          },
        ].map((item, index) => (
          <div key={index} className='d-flex mb-3 me-10'>
            <span className='flex-grow-2 text-muted min-w-150px'>{item.label}</span>
            <span className='flex-grow-1 fw-bold'>{item.value}</span>
          </div>
        ))}
      </div>
    </div>
  )
}

type ContactsTableProps = {
  contacts: Contact[]
}

const ConfirmationModalWithAction = ({ contact }: { contact: Contact }) => {
  const role: userTypes = useUserRole()
  const params: any = useParams()
  const campaignId: number = Number(params?.id)
  const mutation = useDissociateContactMutation(campaignId)

  return (
    <div className='d-flex'>
      {(role === userTypes.Admin || role === userTypes.ClientManager) && (
        <ConfirmationModal
          alertMessage={<AlertMessage contact={contact} />}
          action={() => mutation.mutate(contact?.id as number)}
          renderButton={(rp) => (
            <SpinnerButton {...rp} loading={mutation.isLoading} variant='danger'>
              Remove
            </SpinnerButton>
          )}
        />
      )}
    </div>
  )
}

const ContactsTable: React.FC<any> = (props: ContactsTableProps) => {
  const { contacts } = props
  const history = useHistory()

  const columns: ColumnDef<Contact>[] = [
    {
      accessorFn: (row) => row,
      header: 'Full Name',
      cell: (info: any) => {
        const contact: Contact = info.getValue()
        return (
          <Link
            to={`${history.location.pathname}/${contact.id}`}
            className='text-hover-priamry cursor-pointer'
          >
            {`${contact.firstName} ${contact.lastName}`}
          </Link>
        )
      },
    },
    {
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      id: 'fullName',
      header: 'Full Name',
    },
    { accessorKey: 'jobTitle', header: 'Job Title' },
    { accessorKey: 'emailAddress', header: 'Email Address' },
    { accessorKey: 'mobilePhone', header: 'Mobile Phone' },
    { accessorFn: (row) => row.company?.name, header: 'Company Name' },
    { accessorFn: (row) => row.company?.website, header: 'Website' },
    {
      accessorFn: (row) => row,
      header: 'Actions',
      cell: (info: any) => {
        const contact: Contact = info.getValue()
        return <ConfirmationModalWithAction contact={contact} />
      },
      enableGlobalFilter: false,
      enableSorting: false,
    },
  ]

  return (
    <div className={`card`}>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table columns={columns} data={contacts} hiddenColumns={['fullName']} />
        </div>
      </div>
    </div>
  )
}

export { ContactsTable }
