import { Button } from 'react-bootstrap'
import { useMutation } from 'react-query'
import { useNotificationContext } from 'shared'
import { UserService, IUser } from 'auth'

type ReinviteButtonProps = {
  user: IUser
}

export const ReinviteButton = (props: ReinviteButtonProps) => {
  const { user } = props
  const { setNotification } = useNotificationContext()
  const mutation = useMutation(
    (identityId: any) => {
      return UserService.inviteUser(identityId)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'The re-invitation was sent successfully.',
          type: 'success',
        })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )

  const onInviteUser = async () => {
    const userEmail = user?.userAttributes.find((attr: any) => attr.Name === 'email')?.Value
    await mutation.mutateAsync(userEmail)
  }

  return (
    <Button
      onClick={onInviteUser}
      className='btn btn-sm btn-secondary me-2 float-right'
      disabled={mutation?.isLoading}
    >
      {mutation.isLoading && (
        <span className='spinner-border spinner-border-sm align-middle me-2' />
      )}
      Reinvite
    </Button>
  )
}
