/* eslint-disable jsx-a11y/anchor-is-valid */
import { Tooltip } from 'chart.js'
import React from 'react'
import { Button, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const DuplicatePopover = () => (
  <Popover>
    <Popover.Body>
      <p className='text-warning'>
        This contact already exists for this Campaign and will be skipped.
      </p>
    </Popover.Body>
  </Popover>
)

const FieldValidationPopover = (msg: any) => (
  <Popover>
    <Popover.Body>
      <p className='text-danger'>{msg}</p>
    </Popover.Body>
  </Popover>
)

const ContactReviewTable: React.FC<any> = (props: any) => {
  const history = useHistory()
  const { contacts, payload, mutation } = props

  const onUpload = async () => {
    await mutation.mutateAsync(payload as any)
  }

  const hasContactError = (contact: any) => {
    let hasError = false
    if (contact.validationErrors.length > 0) {
      hasError = true
    }
    return hasError
  }

  const hasContactDuplicates = (contact: any) => {
    if (contact.hasDuplicate > 0) {
      return true
    }
    return false
  }

  const hasAnyErrors = contacts?.some((contact: any) => {
    return hasContactError(contact)
  })

  const renderPopover = (contact: any) => {
    if (contact.validationErrors.length > 0) {
      const validationMessages = contact.validationErrors.map(
        (error: any) => `${Object.values(error?.constraints).toString()}\n`
      )
      return FieldValidationPopover(validationMessages)
    } else if (contact.hasDuplicate) return DuplicatePopover()
    else return <></>
  }

  return (
    <div className={`card`}>
      <div className='card-header pt-8'>
        <h2>Review CSV import </h2>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
            style={{ color: '#eee' }}
          >
            <thead>
              <tr className='fw-bolder text-muted bg-light'>
                <th className='ps-4 min-w-200px'>First Name</th>
                <th className='min-w-200px'>Last Name</th>
                <th className='min-w-200px'>Job Title</th>
                <th className='min-w-200px'>Job Function</th>
                <th className='min-w-200px'>Management Level</th>
                <th className='min-w-200px'>Direct Phone Number</th>
                <th className='min-w-200px'>Email Address</th>
                <th className='min-w-200px'>Email Domain</th>
                <th className='min-w-200px'>Mobile Phone</th>
                <th className='min-w-200px'>City</th>
                <th className='min-w-200px'>State</th>
                <th className='min-w-200px'>Country</th>
                <th className='min-w-200px'>Linkedin</th>
                <th className='min-w-200px'>Company Name</th>
                <th className='min-w-200px'>Website</th>
                <th className='min-w-200px'>Revenue Range</th>
                <th className='min-w-200px'>Employee Range</th>
                <th className='min-w-200px'>Primary Industry</th>
                <th className='min-w-200px'>Secondary Industry</th>
                <th className='min-w-200px'>Company address</th>
                <th className='min-w-200px'>Company address 2</th>
                <th className='min-w-200px'>Company Phone</th>
                <th className='min-w-200px'>Company City</th>
                <th className='min-w-200px'>Company State</th>
                <th className='min-w-200px'>Company Postcode</th>
                <th className='min-w-200px'>Company Country</th>
                <th className='min-w-200px'>Company Linkedin</th>
              </tr>
            </thead>
            <tbody>
              {contacts?.map((contact: any) => {
                const textColor = hasContactError(contact)
                  ? 'text-danger'
                  : hasContactDuplicates(contact)
                  ? 'text-warning'
                  : 'text-dark'

                return (
                  <OverlayTrigger
                    key={contact.id}
                    placement='auto'
                    overlay={renderPopover(contact)}
                  >
                    <tr key={contact.id}>
                      <td className='ps-4 min-w-200px'>
                        <span className={textColor}>{contact.firstName}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.lastName}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.jobTitle}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.jobFunction}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.managementLevel}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.phoneNumber}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.emailAddress}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.emailDomain}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.mobilePhone}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.city}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.state}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.country}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.linkedin}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyName}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.website}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.revenueRange}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.employeeRange}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.primaryIndustry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.secondaryIndustry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyStreet}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyStreetTwo}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.phone}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyCity}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyState}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.postcode}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyCountry}</span>
                      </td>
                      <td>
                        <span className={textColor}>{contact.companyLinkedin}</span>
                      </td>
                    </tr>
                  </OverlayTrigger>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>

      <div className='card-footer'>
        <button
          type='submit'
          className='btn btn-primary'
          onClick={onUpload}
          disabled={hasAnyErrors}
        >
          {mutation.isLoading && (
            <span className='spinner-border spinner-border-sm align-middle mx-2' />
          )}
          Continue
        </button>
        <Button onClick={history.goBack} className='btn btn-secondary mx-10'>
          Cancel & Re-upload CSV
        </Button>
      </div>
    </div>
  )
}

export { ContactReviewTable }
