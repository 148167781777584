import { API } from 'aws-amplify'

const createObjectiveResult = async (objective: any) => {
  return await API.post('client', `/objectives/results`, {
    body: objective,
  })
}

export const ObjectiveService = {
  createObjectiveResult,
}
