import { API, Storage } from 'aws-amplify'
import { Campaign, ICampaign } from 'campaign'

const customPrefix: any = {
  public: 'clients/',
  protected: 'protected/',
  private: 'private/',
}

const getClients = async () => {
  return await API.get('client', `/clients`, {})
}

const getClientsCampaigns = async (id: number): Promise<Campaign[]> => {
  const campaigns = await API.get('client', `/clients/${id}/campaigns`, {})
  return campaigns.map((campaign: ICampaign) => new Campaign(campaign))
}

const getClient = async (id: number) => {
  return await API.get('client', `/clients/${id}`, {})
}

const createClient = async (client: any) => {
  let s3object

  const newClient = await API.post('client', `/clients`, {
    body: client,
  })

  if (client.file) {
    s3object = await Storage.put(`${newClient.id}/${client.file.name}`, client.file, {
      customPrefix,
    })
  }

  return API.put('client', `/clients/${newClient.id}`, {
    body: {
      ...newClient,
      logo: s3object?.key || null,
    },
  })
}

const editClient = async (client: any, id: number) => {
  let s3object
  if (client.file) {
    s3object = await Storage.put(`${id}/${client.file.name}`, client.file, {
      customPrefix,
    })
  }

  return API.put('client', `/clients/${id}`, {
    body: {
      ...client,
      logo: s3object?.key || client.logo,
    },
  })
}

export const ClientService = {
  getClient,
  getClients,
  createClient,
  editClient,
  getClientsCampaigns,
}
