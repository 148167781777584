import dayjs from 'dayjs'
import { Accordion } from 'react-bootstrap'
import { StatBox, Metrics } from 'shared'

import { ObjectiveResult } from '../../../../objective/Objective'
import { MetricSummary } from '../data-and-intelligence/MetricSummary'

export const WeeklyUpdades = ({ lastUpdate }: { lastUpdate: ObjectiveResult }) => {
  return (
    <Accordion defaultActiveKey='0' flush className='card my-10 bg-default px-5'>
      <Accordion.Item eventKey='0'>
        <Accordion.Button style={{ background: '#fff' }} className='flex'>
          <div className='d-flex flex-column card-title align-items-start'>
            <span className='card-label fw-bold fs-3 mb-1'>Latest updates</span>
            <span className='text-muted fw-semibold fs-7'>
              Updates to the campaign since{' '}
              <b>{dayjs(lastUpdate?.startDate).format('YYYY-MM-DD')}</b>
            </span>
          </div>
        </Accordion.Button>
        <Accordion.Body>
          <div className='row'>
            {!!lastUpdate.intents && (
              <StatBox title={Metrics.intentReports} value={lastUpdate.intents} />
            )}
            {!!lastUpdate.companiesDiscovered && (
              <StatBox title={Metrics.campaniesDiscovered} value={lastUpdate.companiesDiscovered} />
            )}
            {!!lastUpdate.contactsDiscovered && (
              <StatBox title={Metrics.contactsDiscovered} value={lastUpdate.contactsDiscovered} />
            )}

            {!!lastUpdate.telesaleHours && (
              <StatBox title={Metrics.telesaleHours} value={lastUpdate.telesaleHours} />
            )}
            {!!lastUpdate.meetingBooked && (
              <StatBox title={Metrics.meetingsBooked} value={lastUpdate.meetingBooked} />
            )}
            {!!lastUpdate.targetPipelineValue && (
              <StatBox title={Metrics.targetPipelineValue} value={lastUpdate.targetPipelineValue} />
            )}
            {!!lastUpdate.dealRegDone && (
              <StatBox title={Metrics.dealRegDone} value={lastUpdate.dealRegDone} />
            )}

            {!!lastUpdate.digitalLeads && (
              <StatBox title={Metrics.digitalLeads} value={lastUpdate.digitalLeads} />
            )}
            {!!lastUpdate.connectionRequestsSent && (
              <StatBox
                title={Metrics.connectionRequestsSent}
                value={lastUpdate.connectionRequestsSent}
              />
            )}
            {!!lastUpdate.connectionRequestsAccepted && (
              <StatBox
                title={Metrics.connectionRequestsAccepted}
                value={lastUpdate.connectionRequestsAccepted}
              />
            )}
            {!!lastUpdate.impressions && (
              <StatBox title={Metrics.impressions} value={lastUpdate.impressions} />
            )}
            {!!lastUpdate.campaignClicks && (
              <StatBox title={Metrics.campaignClicks} value={lastUpdate.campaignClicks} />
            )}
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}

export const CampaignOverview = (props: { campaign: any }) => {
  const { campaign } = props
  const lastUpdate = campaign?.objective?.results[campaign.objective.results.length - 1]

  return (
    <>
      {lastUpdate && <WeeklyUpdades lastUpdate={lastUpdate} />}

      <div className='row g-5 g-xl-8'>
        {campaign.objective?.isDataIntelligenceActive == 1 && (
          <div className='col-xl-6'>
            <MetricSummary
              className='card-xl-stretch mb-xl-8'
              metricInfo={{
                title: 'Data and Intelligence',
                metrics: [
                  campaign.objective?.intents && {
                    title: Metrics.intentReports,
                    link: `/campaign/data-intel/${campaign.id}/dashboard/intent`,
                    target: campaign.objective.intents,
                    value:
                      campaign.objective.results.reduce((a: any, c: any) => a + c.intents, 0) || 0,
                  },
                  campaign.objective?.companiesDiscovered && {
                    title: Metrics.campaniesDiscovered,
                    link: `/campaign/data-intel/${campaign.id}/dashboard/intent`,
                    target: campaign.objective.companiesDiscovered,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.companiesDiscovered,
                        0
                      ) || 0,
                  },
                  campaign.objective?.contactsDiscovered && {
                    title: Metrics.contactsDiscovered,
                    link: `/campaign/data-intel/${campaign.id}/dashboard/contact`,
                    target: campaign.objective.contactsDiscovered,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.contactsDiscovered,
                        0
                      ) || 0,
                  },
                ].filter(Boolean),
              }}
            />
          </div>
        )}

        {campaign.objective?.isTeleProspectingActive == 1 && (
          <div className='col-xl-6'>
            <MetricSummary
              className='card-xl-stretch mb-xl-8'
              metricInfo={{
                title: 'Tele Prospecting',
                metrics: [
                  campaign.objective?.telesaleHours && {
                    title: Metrics.telesaleHours,
                    target: campaign.objective.telesaleHours,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.telesaleHours,
                        0
                      ) || 0,
                  },
                  campaign.objective?.meetingBooked && {
                    title: Metrics.meetingsBooked,
                    target: campaign.objective.meetingBooked,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.meetingBooked,
                        0
                      ) || 0,
                  },
                  campaign.objective?.targetPipelineValue && {
                    title: Metrics.targetPipelineValue,
                    target: campaign.objective.targetPipelineValue,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.targetPipelineValue,
                        0
                      ) || 0,
                  },
                  campaign.objective?.dealRegDone && {
                    title: Metrics.dealRegDone,
                    target: campaign.objective.dealRegDone,
                    value:
                      campaign.objective.results.reduce((a: any, c: any) => a + c.dealRegDone, 0) ||
                      0,
                  },
                ].filter(Boolean),
              }}
            />
          </div>
        )}

        {campaign.objective?.isDigitalProspectingActive == 1 && (
          <div className='col-xl-6'>
            <MetricSummary
              className='card-xl-stretch mb-xl-8'
              metricInfo={{
                title: 'Digital Prospecting',
                metrics: [
                  campaign.objective?.digitalLeads && {
                    title: Metrics.digitalLeads,
                    target: campaign.objective.digitalLeads,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.digitalLeads,
                        0
                      ) || 0,
                  },
                  campaign.objective?.connectionRequestsSent && {
                    title: Metrics.connectionRequestsSent,
                    target: campaign.objective.connectionRequestsSent,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.connectionRequestsSent,
                        0
                      ) || 0,
                  },
                  campaign.objective?.connectionRequestsAccepted && {
                    title: Metrics.connectionRequestsAccepted,
                    target: campaign.objective.connectionRequestsAccepted,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.connectionRequestsAccepted,
                        0
                      ) || 0,
                  },
                  campaign.objective?.impressions && {
                    title: Metrics.impressions,
                    target: campaign.objective.impressions,
                    value:
                      campaign.objective.results.reduce((a: any, c: any) => a + c.impressions, 0) ||
                      0,
                  },
                  campaign.objective?.campaignClicks && {
                    title: Metrics.campaignClicks,
                    target: campaign.objective.campaignClicks,
                    value:
                      campaign.objective.results.reduce(
                        (a: any, c: any) => a + c.campaignClicks,
                        0
                      ) || 0,
                  },
                ].filter(Boolean),
              }}
            />
          </div>
        )}
      </div>
    </>
  )
}
