import { toAbsoluteUrl } from '../../../helpers'

export function FallbackView() {
  return (
    <div className='splash-screen'>
      <img src={toAbsoluteUrl('/media/logos/oSpace.svg')} alt='Ospace Loading logo' />
      <span>Loading ...</span>
    </div>
  )
}
