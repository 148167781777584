import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { Formik } from 'formik'
import { useCheckRole } from 'auth'
import { useNotificationContext, userTypes, ErrorBoundary } from 'shared'

import { PageTitle } from '../../../_metronic/layout/core'
import { ClientService } from '../services'
import { Client } from '../Client'
import { createClientSchema } from '../components/CreateClientHelper'
import { ClientForm } from '../components/ClientForm'
import { useClients } from '../hooks'

type Props = { clientId: number }
export const EditClientPage = (props: Props) => {
  useClients()
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const history = useHistory()
  const {
    status,
    data: client,
    error,
    isFetching,
  } = useQuery(['client', props.clientId], () => ClientService.getClient(props.clientId))
  const mutation = useMutation(
    (cli: Client) => ClientService.editClient({ ...cli, logo: client?.logo }, props.clientId),
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: ' You have successfully edited the client.',
          type: 'success',
        })

        queryClient.invalidateQueries(['client', props.clientId])
        queryClient.invalidateQueries('clients')
      },
    }
  )

  return (
    <>
      <PageTitle>Edit Client</PageTitle>

      <div className='card'>
        {isFetching ? (
          <div className='d-flex align-items-center justify-content-center'>
            <Spinner animation='grow'></Spinner>
          </div>
        ) : status === 'error' ? (
          <span>Error: {(error as any).message}</span>
        ) : (
          <Formik
            initialValues={{
              name: client?.name,
              website: client?.website,
              active: client?.active,
            }}
            validationSchema={createClientSchema}
            onSubmit={async (values) => {
              await mutation.mutateAsync(values as any)
              history.push(`/client/view/${client?.id}`)
            }}
          >
            {(props) => (
              <div className='d-flex align-items-center'>
                <ClientForm form={props} mutation={mutation} />
                <img src={client?.logoUrl} className='mh-200px' alt={`${client?.name} logo`} />
              </div>
            )}
          </Formik>
        )}
      </div>
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager])
  const params: any = useParams()
  const clientId = Number(params?.id)
  return <EditClientPage clientId={clientId} />
}

export const EditClientPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
