import React, { useState } from 'react'
import { Accordion, Button, Collapse } from 'react-bootstrap'
import { Contact } from '../models/Contact'

type PersonBoxProps = {
  contact: Contact
}
export const PersonBox: React.FC<PersonBoxProps> = (props: PersonBoxProps) => {
  const { contact } = props
  const [open, setOpen] = useState(true)
  return (
    <div className='card'>
      <div className='card-header border-0 py-8'>
        <h3 className='card-title align-items-center flex-column w-100'>
          <span className='card-label font-weight-bolder text-dark'>{`${contact.firstName} ${contact.lastName}`}</span>
          <span className='text-muted mt-3 font-weight-bold fs-6'>{contact.jobTitle}</span>
        </h3>
      </div>
      <div className='card-body'>
        <h3
          onClick={() => setOpen(!open)}
          aria-controls='personbox-collapse-text'
          aria-expanded={open}
          className='card-title align-items-start flex-column w-100 text-hover-primary mt-2 mb-8'
        >
          Details
          {open ? (
            <i className='bi bi-chevron-up text-hover-primary mx-2 fw-bold'></i>
          ) : (
            <i className='bi bi-chevron-down text-hover-primary mx-2 fw-bold'></i>
          )}
        </h3>
        <Collapse in={open}>
          <div id='personbox-collapse-text'>
            {[
              {
                label: 'LinkedIn',
                value: contact.linkedin,
              },
              {
                label: 'Email Address',
                value: contact.emailAddress,
              },
            ].map((item, index) => {
              return (
                <div
                  key={index}
                  className='d-flex align-items-center justify-content-between mb-10'
                >
                  <div className='d-flex align-items-center mr-2'>
                    <div>
                      <div className='font-size-h6 text-dark-75 fw-bold'>{item.label}</div>
                      <div className='font-size-sm text-muted fw-bold mt-1'>{item.value}</div>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Collapse>
      </div>
    </div>
  )
}
