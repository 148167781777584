import { useMutation, useQueryClient } from 'react-query'
import { useNotificationContext } from 'shared'
import { UserService } from 'auth'

export const useDisableUser = (identityId: string) => {
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  return useMutation(
    (username: string) => {
      return UserService.disableUser(username)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'The user was sent successfully disabled.',
          type: 'success',
        })

        queryClient.setQueryData(['users'], (users: any) => {
          const user = users.find((d: any) => d.identityId === identityId)
          user.enabled = false
          return users
        })
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.response?.data || e.message,
          type: 'danger',
        })
      },
    }
  )
}
