import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const Step2: FC<any> = (props: any) => {
  const { values } = props
  return (
    <div className='w-100'>
      <div className='fv-row mb-10 d-flex flex-stack'>
        <h3 className='stepper-title'>Data and Intelligence</h3>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field name='isDataIntelligenceActive' className='form-check-input' type='checkbox' />
        </label>
      </div>

      <div className='fv-row'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span>Objectives</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your objectives'
          ></i>
        </label>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Number of Intent Reports</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='intents'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='intents' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Companies discovered</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='companiesDiscovered'
                required
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='companiesDiscovered' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Contacts discovered</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='contactsDiscovered'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='contactsDiscovered' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step2 }
