/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { Logout } from 'auth'
import { useAuthContext } from 'auth'
import { useAuthCheck } from 'auth'
import { TokenPage } from 'auth'

import { FallbackView } from '../../../_metronic/partials'
import { MasterLayout } from '../../../_metronic/layout/MasterLayout'
import { PrivateRoutes } from './PrivateRoutes'
import { PublicRoutes } from './PublicRoutes'
import { ErrorsPage } from '../pages/ErrorsPage'

const Routes: FC = () => {
  useAuthCheck()

  const { session, loading } = useAuthContext()

  return (
    <Switch>
      <Route path='/auth/token' component={TokenPage} />

      {!session ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route>{loading ? <FallbackView /> : <PublicRoutes />}</Route>
      ) : (
        /*Otherwise redirect to root page (`/`)*/
        <Redirect from='/auth' to='/' />
      )}

      <Route path='/error' component={ErrorsPage} />
      <Route path='/logout' component={Logout} />

      {!session ? (
        /*Redirect to `/auth` when user is not authorized*/
        <Redirect to='/auth/login' />
      ) : (
        <MasterLayout>{loading ? <FallbackView /> : <PrivateRoutes />}</MasterLayout>
      )}
    </Switch>
  )
}

export { Routes }
