/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'

type Props = {
  totalGoal?: number
  className: string
  metricInfo: {
    title: string
    subTitle?: string
    metrics: Array<{ title: string; target?: number; value: number }>
  }
}

const MetricSummary: React.FC<Props> = ({ className, totalGoal, metricInfo }) => {
  return (
    <>
      <div className={`card ${className}`}>
        {/* begin::Beader */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>{metricInfo.title}</span>

            <span className='text-muted fw-bold fs-7'>{metricInfo?.subTitle}</span>
          </h3>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body p-0 d-flex flex-column'>
          {/* begin::Stats */}
          <div className='card-p pt-5 bg-white flex-grow-1'>
            {/* begin::Row */}
            <div className='row g-0'>
              <></>
              {metricInfo.metrics.map((metric: any, key: number) => (
                <div className='col mr-8' key={key}>
                  {/* begin::Label */}
                  <div className='fs-7 text-muted fw-bold'>{metric?.title}</div>
                  {/* end::Label */}

                  {/* begin::Stat */}
                  <div className='d-flex align-items-center'>
                    <div className='fs-4 fw-bolder'>{metric?.value}</div>
                  </div>
                  {/* end::Stat */}
                </div>
              ))}
            </div>
            {/* end::Row */}
          </div>
          {metricInfo.metrics.map((metric: any, key: number) => (
            <div className='card-body' key={key}>
              {metric?.link ? (
                <Link to={metric?.link}>
                  <h3 className='card-title fw-bolder text-hover-primary fs-5 mb-3 d-block'>
                    {metric.title} progress
                  </h3>
                </Link>
              ) : (
                <h3 className='card-title fw-bolder fs-5 mb-3 d-block'>{metric.title} progress</h3>
              )}
              <span className='fs-2 fw-bolder me-2'>
                {Math.ceil((metric.value / metric.target) * 100)}%
              </span>
              <span className='text-dark fs-2 fw-bolder me-2 float-end'>{metric.target} Goal</span>
              <ProgressBar
                className='progress h-7px bg-opacity-50 mt-7'
                now={metric.value}
                max={metric.target}
                animated={false}
                striped={false}
              />
            </div>
          ))}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export { MetricSummary }
