import React from 'react'

import { Form } from 'formik'
import { Field, ErrorMessage } from 'formik'
import { Link } from 'react-router-dom'

type UserProfileEditFormProps = {
  isSubmitting: boolean
}

export const UserProfileEditForm: React.FC<UserProfileEditFormProps> = (
  props: UserProfileEditFormProps
) => {
  const { isSubmitting } = props
  return (
    <Form className='form' noValidate id='kt_create_account_form'>
      <div className='card-body fv-row mb-10'>
        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>First Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a first name for the user'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='firstName'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='firstName' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span className='required'>Last Name</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a last name for the user'
            ></i>
          </label>

          <Field
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='lastName'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='lastName' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
            <span>Job Title</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Specify a job title for the user'
            ></i>
          </label>

          <Field
            data-testid='jobTitle'
            type='text'
            className='form-control form-control-lg form-control-solid'
            name='jobTitle'
            placeholder=''
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='jobTitle' />
          </div>
        </div>

        <button type='submit' className='btn btn-primary' disabled={isSubmitting}>
          {isSubmitting && <span className='spinner-border spinner-border-sm align-middle mx-2' />}
          Submit
        </button>
        <Link to='/home' className='btn btn-secondary mx-10'>
          Cancel
        </Link>
      </div>
    </Form>
  )
}
