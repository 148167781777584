import { useQuery } from 'react-query'
import { CampaignService } from '../services'
import { ClientService } from 'client'

export const useCampaigns = (enabled: boolean = true) => {
  return useQuery('campaigns', CampaignService.getCampaigns, { enabled })
}

export const useCampaign = (id: number) => {
  return useQuery(['campaign', id], () => CampaignService.getCampaign(id))
}

export const useClientCampaign = (clientId: any) => {
  return useQuery(
    ['client-campaign', clientId],
    () => ClientService.getClientsCampaigns(clientId),
    {
      enabled: !!clientId,
    }
  )
}
