import { Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useUserRole } from 'auth'
import { userTypes, ErrorBoundary } from 'shared'
import { CampaignsPage, MyCampaignsPage } from 'campaign'

import { PageTitle } from '../../../_metronic/layout/core'
import { Widget } from '../components/Widget'
import { useStats } from '../hooks'

type Props = {
  role: userTypes
}
export const HomePage = (props: Props) => {
  const isAdminOrClientManager =
    props.role === userTypes.Admin || props.role === userTypes.ClientManager
  const isCampaignUser = props.role === userTypes.CampaignUser
  const { data: stats, isFetching, isSuccess } = useStats(isAdminOrClientManager)

  let adminWidgets: any
  if (isSuccess) {
    adminWidgets = [
      {
        title: 'Client Management',
        link: '/client',
        sub: [
          {
            icon: 'boxes',
            count: stats?.clients,
            title: 'Clients',
          },
          {
            icon: 'arrow-up-right-square-fill',
            count: stats?.activeCampaigns,
            title: 'Active campaigns',
          },
          {
            icon: 'arrow-up-right-square-fill',
            count: stats?.endingCampaigns,
            title: 'Campaigns ending soon',
          },
        ],
      },
    ]
    props.role === userTypes.Admin &&
      adminWidgets.push({
        title: 'User Management',
        link: '/user',
        sub: [
          {
            icon: 'person',
            count: stats?.users,
            title: 'Active users',
          },
          {
            icon: 'arrow-up-right-square-fill',
            count: stats?.pendingInvitations,
            title: 'Pending invitations',
          },
        ],
      })
    adminWidgets.push({
      title: 'Integration Management',
      link: '#',
      disabled: true,
      sub: [],
    })
  }

  return (
    <>
      <PageTitle>Home</PageTitle>
      {isFetching && (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      )}
      {isAdminOrClientManager ? (
        <div className='row g-5 g-xl-8'>
          {adminWidgets?.map((data: any, key: number) => (
            <Widget widget={data} key={key} />
          ))}
        </div>
      ) : isCampaignUser ? (
        <MyCampaignsPage />
      ) : (
        <CampaignsPage />
      )}
    </>
  )
}

const Controller = () => {
  const role: any = useUserRole()
  const history = useHistory()
  if (role === userTypes.ClientViewer) {
    history.push('/client')
  }

  return <HomePage role={role} />
}

export const HomePageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
