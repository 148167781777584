import { CampaignPage } from 'campaign'
import { ErrorBoundary, userTypes } from 'shared'
import { useParams } from 'react-router'
import { FileManager } from '../components/FileManager'
import { useUserRole } from 'auth'

type FileManagerPageProps = {
  campaignId: number
  isCampaignManager: boolean
}
export const FileManagerPage = (props: FileManagerPageProps) => {
  return (
    <CampaignPage>
      <FileManager {...props} />
    </CampaignPage>
  )
}

const Controller = () => {
  const params: any = useParams()
  const campaignId = Number(params?.id)
  const userRole = useUserRole()

  return (
    <FileManagerPage
      campaignId={campaignId}
      isCampaignManager={userRole <= userTypes.CampaignManager}
    />
  )
}

export const FileManagerPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
