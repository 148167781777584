import dayjs from 'dayjs'
import { Client } from 'client'
import { ICP } from './ICP'
import { IntentReportConfiguration } from './IntentReportConfiguration'
import { Objective } from 'objective'
import * as Yup from 'yup'

export const createCampaignRequestSchema = Yup.object({
  client: Yup.number().positive(),
  name: Yup.string().required().label('Campaign name'),
  startDate: Yup.string().required().label('Start date'),
  lookerClientId: Yup.number().nullable().label('Looker client id'),
  intents: Yup.number().when('isDataIntelligenceActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Intents'),
  }),
  companiesDiscovered: Yup.number().when('isDataIntelligenceActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Companies discovered'),
  }),
  contactsDiscovered: Yup.number().when('isDataIntelligenceActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Contacts discovered'),
  }),
  telesaleHours: Yup.number().when('isTeleProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Telesales hours'),
  }),
  meetingBooked: Yup.number().when('isTeleProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Meetings Booked'),
  }),
  targetPipelineValue: Yup.number().when('isTeleProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Target pipeline value'),
  }),
  dealRegDone: Yup.number().when('isTeleProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Deal reg done'),
  }),
  digitalLeads: Yup.number().when('isDigitalProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Digital leads'),
  }),
  connectionRequestsSent: Yup.number().when('isDigitalProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Connection requests sent'),
  }),
  connectionRequestsAccepted: Yup.number().when('isDigitalProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Connection requests accepted'),
  }),
  impressions: Yup.number().when('isDigitalProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Impression'),
  }),
  campaignClicks: Yup.number().when('isDigitalProspectingActive', {
    is: true,
    then: Yup.number().positive().nullable().integer().label('Campaign clicks'),
  }),
  icps: Yup.array().of(
    Yup.object({
      // TODO: optional id
      name: Yup.string().required('ICP name is required field'),
      industryId: Yup.number().positive().nullable(),
      employeeRangeId: Yup.number().positive().nullable(),
      revenueRangeId: Yup.number().positive().nullable(),
    })
  ),
  intentReportConfigurations: Yup.array()
    .of(
      Yup.object({
        reportId: Yup.string().uuid('Report id must be a guid/uuid type').label('Report id'),
        accountType: Yup.string().required(),
      })
    )
    .test('unique', 'Report id must be unique', (values: any) => {
      const uuids = values.map((value: { reportId: string }) => value.reportId)
      return new Set(uuids).size === values.length
    }),
})

export type CreateCampaignRequest = Yup.InferType<typeof createCampaignRequestSchema>

export enum CampaignStatus {
  InProgress = 'In Progress',
  Paused = 'Paused',
  Ended = 'Ended',
}

export interface ICampaign {
  id: number
  name: string
  description: string | null
  lookerClientId: string | null
  startDate: Date
  endDate: Date | null
  createdAt?: Date
  updatedAt?: Date
  client: Client
  icps: ICP[]
  objective: Objective
  lookerDashboardForIntent?: string
  lookerDashboardForIntentAndEngagement?: string
  lookerDashboardForContact?: string
  status: CampaignStatus
  formattedStartDate: string
  formattedEndDate?: string
  intentReportConfigurations:
    | {
        reportId: string
        accountType: string
      }[]
    | IntentReportConfiguration[]
}

export class Campaign implements ICampaign {
  public id
  public name
  public description
  public lookerClientId
  public startDate
  public endDate
  public client
  public icps
  public objective
  public createdAt
  public updatedAt
  public lookerDashboardForIntent?: string | undefined
  public lookerDashboardForIntentAndEngagement?: string | undefined
  public lookerDashboardForContact?: string | undefined
  public status
  public formattedStartDate
  public formattedEndDate
  public intentReportConfigurations:
    | {
        reportId: string
        accountType: string
      }[]
    | IntentReportConfiguration[] // Q: why are there 2 shapes?

  // Q: why a class?
  constructor(campaign: ICampaign) {
    this.id = campaign.id
    this.name = campaign.name
    this.description = campaign.description
    this.lookerClientId = campaign.lookerClientId
    this.startDate = campaign.startDate
    this.endDate = campaign.endDate
    this.createdAt = campaign.createdAt
    this.updatedAt = campaign.updatedAt
    this.client = campaign.client
    this.icps = campaign.icps
    this.objective = campaign.objective
    this.lookerDashboardForIntent = campaign?.lookerDashboardForIntent
    this.lookerDashboardForIntentAndEngagement = campaign?.lookerDashboardForIntentAndEngagement
    this.lookerDashboardForContact = campaign?.lookerDashboardForContact
    this.status = campaign.status
    this.formattedStartDate = dayjs(campaign?.startDate).format('DD MMM,  YYYY')
    this.formattedEndDate = campaign.endDate
      ? dayjs(campaign?.endDate).format('DD MMM,  YYYY')
      : undefined

    // Q: is this why there are 2 shapes for iRC, to support mapping in constructor?
    this.intentReportConfigurations = campaign?.intentReportConfigurations?.map((iRC: any) => {
      return {
        id: iRC.id,
        reportId: iRC.configuration.reportId,
        accountType: iRC.accountType,
      }
    })
  }
}

export function isIntentReportConfiguration(
  obj: { reportId: string; accountType: string } | IntentReportConfiguration
): obj is IntentReportConfiguration {
  if ((obj as IntentReportConfiguration).configuration) {
    return true
  }
  return false
}
