import { Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { FallbackView } from '../../../_metronic/partials'
import {
  ClientPageRouteHandler,
  CreateClientPageRouteHandler,
  EditClientPageRouteHandler,
} from 'client'
import { CreateObjectiveResultPage } from 'objective'
import { HomePageRouteHandler } from '@ospace/home'
import {
  UserPageRouteHandler,
  CreateUserPageRouteHandler,
  SsoInfoPage,
  EditUserPageRouteHandler,
  EditUserProfilePageRouteHandler,
} from 'user'

import { FileManagerPageRouteHandler } from 'file-manager'
import {
  CampaignsPage,
  CreateCampaignPageRouteHandler,
  DataIntelPageRouteHandler,
  EditCampaignPageRouteHandler,
  OverviewPageRouteHandler,
} from 'campaign'

import {
  ContactDetailPageRouteHandler,
  ContactReviewPageRouteHandler,
  ContactsPageRouteHandler,
  ContactUploadPageRouteHandler,
} from 'contact'

export function PrivateRoutes() {
  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route path='/dashboard' component={HomePageRouteHandler} />
        <Route path='/home' component={HomePageRouteHandler} />
        <Route path='/campaign/edit/:id' exact component={EditCampaignPageRouteHandler} />
        <Route path='/campaign/view/:id' exact component={OverviewPageRouteHandler} />
        <Route
          path='/campaign/:id/contact/upload'
          exact
          component={ContactUploadPageRouteHandler}
        />
        <Route path='/campaign/:id/contact' exact component={ContactsPageRouteHandler} />
        <Route
          path='/campaign/:id/contact/review'
          exact
          component={ContactReviewPageRouteHandler}
        />
        <Route
          path='/campaign/:id/contact/:contactId'
          exact
          component={ContactDetailPageRouteHandler}
        />
        <Route path='/campaign/:id/objective/create' exact component={CreateObjectiveResultPage} />
        <Route path='/campaign/:id/files' exact component={FileManagerPageRouteHandler} />
        <Route
          path='/campaign/data-intel/:id/dashboard/:intelType'
          exact
          component={DataIntelPageRouteHandler}
        />

        <Route path='/client' exact component={ClientPageRouteHandler} />
        <Route path='/client/view/:id' exact component={CampaignsPage} />
        <Route path='/client/create' exact component={CreateClientPageRouteHandler} />
        <Route path='/client/edit/:id' exact component={EditClientPageRouteHandler} />
        <Route
          path='/client/:clientId/campaign/create'
          exact
          component={CreateCampaignPageRouteHandler}
        />

        <Route path='/user' exact component={UserPageRouteHandler} />
        <Route path='/user/edit/:id' exact component={EditUserPageRouteHandler} />
        <Route path='/user/edit' exact component={EditUserProfilePageRouteHandler} />
        <Route path='/user/create' exact component={CreateUserPageRouteHandler} />

        <Route path='/sso' exact component={SsoInfoPage} />

        <Redirect exact from='/' to='/home' />
        <Redirect to='/error/404' />
      </Switch>
    </Suspense>
  )
}
