import { useEffect } from 'react'
import { useHistory } from 'react-router'
import { AuthLayout } from '../components/AuthLayout'
import { Hub } from 'aws-amplify'
import { useNotificationContext } from 'shared'

import { UserService } from '../services/user'

export function TokenPage() {
  const history = useHistory()
  const { setNotification } = useNotificationContext()

  const handleSuccess = () => {
    setNotification({
      active: true,
      type: 'success',
      message: 'You have successfully logged in.',
    })
  }

  const handleError = () => {
    setNotification({
      active: true,
      type: 'error',
      message: 'Something went wrong with SSO.',
    })
  }
  useEffect(() => {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      if (event === 'signIn') {
        const user: any = (data as any)?.signInUserSession
        const payLoad: any = {
          identityId: user?.idToken?.payload['cognito:username'],
          userGroups: user?.idToken?.payload['custom:usergroups'],
          firstName: user?.idToken?.payload?.given_name,
          lastName: user?.idToken?.payload?.family_name,
        }

        // Attempt to create user or just login
        const res = await UserService.createSSOUser(payLoad)
        /*
          Response 201 shows user have been created so redirect to SSO info page 
          User in this login session is not assigned to group yet, so they need to logout and login back
          Reponse 200 shows user already exits so redirect to homepage
        */
        if (res === 201) {
          handleSuccess()
          history.push('/sso')
        } else if (res === 200) {
          handleSuccess()
          history.push('/home')
        } else {
          handleError()
          history.push('/auth/login')
        }
      }
    })
  }, [])

  return <AuthLayout>Logging in ....</AuthLayout>
}
