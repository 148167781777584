import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { Formik } from 'formik'
import { Spinner } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import { useNotificationContext, userTypes } from 'shared'
import { useCampaign, useCampaigns } from 'campaign'

import { PageTitle } from '../../../_metronic/layout/core'
import { useCheckRole } from 'auth'
import { ObjectiveForm } from '../components/ObjectiveForm'
import { ObjectiveService } from '../services'
import { ObjectiveResult } from '../Objective'
import { inits, createOjectiveResultSchema } from '../components/CreateObjectiveHelper'

const CreateObjectiveResultPage: React.FC = () => {
  useCheckRole([userTypes.Admin, userTypes.ClientManager, userTypes.CampaignManager])
  const queryClient = useQueryClient()
  const params: any = useParams()
  const history = useHistory()
  const { setNotification } = useNotificationContext()

  useCampaigns()
  const campaignId = Number(params?.id)
  const { status, data: campaign, error, isFetching } = useCampaign(campaignId)
  const mutation = useMutation(
    (objective: ObjectiveResult) => {
      const objectResultDTO = {
        ...objective,
        objective: campaign?.objective?.id,
        campaign: {
          name: campaign?.name,
          id: campaign?.id,
        },
        client: {
          id: campaign?.client?.id,
          name: campaign?.client?.name,
        },
      }
      return ObjectiveService.createObjectiveResult(objectResultDTO)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'You have successfully updated the campaign.',
          type: 'success',
        })
        queryClient.invalidateQueries('campaigns')
        queryClient.invalidateQueries('campaign')
      },
    }
  )

  return (
    <>
      <PageTitle>Add Objective Update</PageTitle>

      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : status === 'error' ? (
        <span>Error: {(error as any).message}</span>
      ) : (
        <>
          <div className='card'>
            <Formik
              initialValues={inits}
              validationSchema={createOjectiveResultSchema}
              onSubmit={async (values, actions) => {
                await mutation.mutateAsync(values as any)
                actions.resetForm()
                history.goBack()
              }}
            >
              {(props) => (
                <ObjectiveForm
                  mutation={mutation}
                  form={props}
                  campaign={campaign}
                  backLink={`/campaign/view/${campaign?.id}`}
                />
              )}
            </Formik>
          </div>
        </>
      )}
    </>
  )
}

export { CreateObjectiveResultPage }
