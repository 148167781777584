import { API, Storage } from 'aws-amplify'

const customPrefix: any = {
  public: 'uploads/',
  protected: 'protected/',
  private: 'private/',
}

const getContactsForCampaign = async (campaignId: number) => {
  return API.get('client', `/contacts/campaigns/${campaignId}`, {})
}

const validateContact = async (contact: any) => {
  let s3object
  if (contact.file) {
    s3object = await Storage.put(
      `${Date.now()}-${contact.lookerClientId}-${contact.file.name}`,
      contact.file,
      {
        bucket: process.env.REACT_APP_PROCESSOR_BUCKET,
        customPrefix,
      }
    )
  }

  const contactDTO = {
    ...contact,
    key: s3object?.key,
  }
  return API.post('client', `/contacts:validate`, {
    body: contactDTO,
  })
}

const processContact = async (contact: any) => {
  let s3object
  if (contact.file) {
    s3object = await Storage.put(
      `${Date.now()}-${contact.lookerClientId}-${contact.file.name}`,
      contact.file,
      {
        bucket: process.env.REACT_APP_PROCESSOR_BUCKET,
        customPrefix,
      }
    )
  }

  const contactDTO = {
    ...contact,
    key: s3object?.key,
  }
  return API.post('client', `/contacts/csvs`, {
    body: contactDTO,
  })
}

const cleanContacts = async (lookerClientId: any) => {
  return API.post('client', `/contacts:clean`, {
    body: {
      lookerClientId,
    },
  })
}

const dissociateContactFromCampaign = async (contactId: number, campaignId: number) => {
  return API.del('client', `/contacts/${contactId}/campaigns/${campaignId}`, {})
}

export const ContactService = {
  processContact,
  cleanContacts,
  validateContact,
  getContactsForCampaign,
  dissociateContactFromCampaign,
}
