/* eslint-disable jsx-a11y/anchor-is-valid */
import { ColumnDef } from '@tanstack/react-table'
import React from 'react'
import { Link } from 'react-router-dom'
import { userTypes, Table } from 'shared'
import { useUserRole } from 'auth'

import { KTSVG } from '../../../_metronic/helpers'
import { Client } from '../Client'

const withHttp = (url: string) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url)

const ClientsTable: React.FC<any> = (props: any) => {
  const { clients } = props
  const role: any = useUserRole()
  const sortedClients = clients.sort((a: any, b: any) => a.name.localeCompare(b.name))

  const columns: ColumnDef<Client>[] = [
    {
      accessorFn: (row) => row,
      header: 'Logo',
      cell: (info) => {
        const client: Client = info.getValue()
        return (
          <Link to={`/client/view/${client.id}`}>
            <img
              src={client.logoUrl}
              className='w-100px h-100px'
              style={{ objectFit: 'contain' }}
            />
          </Link>
        )
      },
      enableSorting: false,
    },
    {
      accessorFn: (row) => row.name,
      header: 'Name',
      cell: (info: any) => info.getValue(),
    },
    {
      accessorKey: 'website',
      header: 'Website',
      cell: (info: any) => {
        let website = info.getValue()
        return website ? (
          <a
            href={withHttp(website)}
            className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6'
          >
            {info.getValue()}
          </a>
        ) : (
          <></>
        )
      },
    },
    {
      accessorKey: 'active',
      header: 'Status',
      cell: (info: any) => {
        return (
          <span
            className={info.getValue() ? 'badge badge-light-success' : 'badge badge-light-warning'}
          >
            {info.getValue() ? 'Active' : 'Not Active'}
          </span>
        )
      },
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => row,
      header: 'Actions',
      cell: (info: any) => {
        const client = info.getValue()
        return (
          <div className='d-flex'>
            <Link to={`/client/view/${client.id}`} className='btn btn-secondary btn-sm px-4 me-3 '>
              View
            </Link>

            {(role === userTypes.Admin || role === userTypes.ClientManager) && (
              <Link to={`/client/edit/${client.id}`} className='btn btn-secondary btn-sm px-4 me-3'>
                Edit
              </Link>
            )}
          </div>
        )
      },
      enableGlobalFilter: false,
    },
  ]

  return (
    <div className={`card`}>
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder fs-3 mb-1'>All Clients</span>
        </h3>
        <div className='card-toolbar'>
          <div className='card-toolbar'>
            {(role === userTypes.Admin || role === userTypes.ClientManager) && (
              <Link to='/client/create' className='btn btn-sm btn-light-primary'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                New Client
              </Link>
            )}
          </div>
        </div>
      </div>
      <div className='card-body py-3'>
        <div className='table-responsive'>
          <Table columns={columns} data={sortedClients} />
        </div>
      </div>
    </div>
  )
}

export { ClientsTable }
