/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import { ColumnDef } from '@tanstack/react-table'
import { Table, ConfirmationModal, SpinnerButton, UserStatus, userTypes } from 'shared'

import { KTSVG } from '../../../_metronic/helpers'
import { useUserRole, IUser, User } from 'auth'
import { ReinviteButton } from './ReinviteButton'
import { Client } from '../../client/Client'
import { useUser } from '../hooks/useUser'
import { useDisableUser } from '../hooks/useDisableUser'
import { useEnableUser } from '../hooks/useEnableUser'

const ConfirmationModalWithDisableAction = ({ user }: { user: IUser }) => {
  const mutation = useDisableUser(user.identityId as string)

  return (
    <ConfirmationModal
      alertMessage={
        <div>
          <p className='fs-4'>
            Are you sure you want to disable access to the platform for{' '}
            <b>
              {user.firstName} {user.lastName}
            </b>
            ?
          </p>
        </div>
      }
      action={() => mutation.mutate(user?.identityId as string)}
      renderButton={(rp) => (
        <SpinnerButton {...rp} loading={mutation.isLoading} variant='secondary'>
          Disable
        </SpinnerButton>
      )}
    />
  )
}

const ConfirmationModalWithEnableAction = ({ user }: { user: IUser }) => {
  const mutation = useEnableUser(user.identityId as string)

  return (
    <ConfirmationModal
      alertMessage={
        <div>
          <p className='fs-4'>
            Are you sure you want to enable the access to the platform for{' '}
            <b>
              {user.firstName} {user.lastName}
            </b>
            ?
          </p>
        </div>
      }
      action={() => mutation.mutate(user?.identityId as string)}
      renderButton={(rp) => (
        <SpinnerButton {...rp} loading={mutation.isLoading} variant='secondary'>
          Enable
        </SpinnerButton>
      )}
    />
  )
}

const UsersTable: React.FC<any> = (props: any) => {
  const { users } = props

  const usersForTable = users.map((user: User) => {
    const clients = new Map()
    user?.client && clients.set(user.client.id, user?.client)
    user?.clients.map((client: Client) => clients.set(client.id, client))
    return {
      ...user,
      clients: Array.from(clients.values()),
    } as User
  })

  const role = useUserRole()
  const { data: currentUser } = useUser()

  const columns: ColumnDef<User>[] = [
    {
      accessorFn: (row) => `${row.firstName} ${row.lastName}`,
      id: 'name',
      header: 'Name',
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: 'email',
      header: 'Username',
    },
    //Hidden clients field, used for search only
    {
      accessorFn: (row) => row.clients.map((client: Client) => client.name).join(', '),
      id: 'clientsForSearch',
      header: 'Clients',
      cell: (info) => info.getValue(),
      enableHiding: true,
    },

    {
      accessorFn: (row) => row.clients,
      id: 'clients',
      header: 'Clients',
      cell: (info: any) => {
        const clients = info.getValue()
        return (
          <div className='d-flex'>
            {clients.map((client: Client, index: number) => (
              <Link
                key={client.id}
                to={`/client/view/${client?.id}`}
                className='text-dark fw-bolder text-hover-primary d-block mb-1 fs-6 me-1'
              >
                {client?.name}
                {index === clients.length - 1 ? `` : `,`}
              </Link>
            ))}
          </div>
        )
      },
    },
    {
      accessorKey: 'userStatus',
      header: 'Status',
      cell: (info) => {
        return (
          <span
            className={
              info.getValue() === UserStatus.FORCE_CHANGE_PASSWORD
                ? 'badge badge-light-warning'
                : 'badge badge-light-success'
            }
          >
            {info.getValue()}
          </span>
        )
      },
    },
    {
      accessorFn: (row) => row,
      id: 'actions',
      header: 'Actions',
      cell: (info) => {
        const user: User = info.getValue()
        const isCurrentUser = user.id === currentUser?.id
        return (
          role === userTypes.Admin && (
            <div className='min-w-300px'>
              <Link
                to={`/user/edit/${user.identityId}`}
                className='btn btn-secondary btn-sm px-4 me-2'
              >
                Edit
              </Link>
              {user.userStatus === UserStatus.FORCE_CHANGE_PASSWORD && (
                <ReinviteButton user={user} />
              )}
              {user.enabled === true
                ? !isCurrentUser && <ConfirmationModalWithDisableAction user={user} />
                : !isCurrentUser && <ConfirmationModalWithEnableAction user={user} />}
            </div>
          )
        )
      },
      enableSorting: false,
    },
  ]
  return (
    <>
      <div className={`card`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bolder fs-3 mb-1'>All Users</span>
          </h3>
          <div className='card-toolbar'>
            {/* begin::Menu */}
            <div className='card-toolbar'>
              <Link to='/user/create' className='btn btn-sm btn-light-primary'>
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                New User
              </Link>
            </div>
            {/* end::Menu */}
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <Table columns={columns} data={usersForTable} hiddenColumns={['clientsForSearch']} />
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export { UsersTable }
