import React, { FC } from 'react'
import { Field, ErrorMessage } from 'formik'

const Step3: FC<any> = (props: any) => {
  const { values } = props
  return (
    <div className='w-100'>
      <div className='fv-row mb-10 d-flex flex-stack'>
        <h3 className='stepper-title'>Teleprospecting</h3>
        <label className='form-check form-switch form-check-custom form-check-solid'>
          <Field name='isTeleProspectingActive' className='form-check-input' type='checkbox' />
        </label>
      </div>

      <div className='fv-row'>
        <label className='d-flex align-items-center fs-5 fw-bold mb-4'>
          <span>Objectives</span>
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Specify your objectives'
          ></i>
        </label>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Telesales Hours</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='telesaleHours'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='telesaleHours' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Meetings Booked</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='meetingBooked'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='meetingBooked' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Target Pipeline Value</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='targetPipelineValue'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='targetPipelineValue' />
          </div>
        </div>

        <div className='mb-10'>
          <label className='d-flex flex-stack cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Number of Deal Reg</span>
              </span>
            </span>
            <span>
              <Field
                type='number'
                className='form-control form-control-lg form-control-solid'
                name='dealRegDone'
                placeholder=''
              />
            </span>
          </label>
          <div className='text-danger mt-2'>
            <ErrorMessage name='dealRegDone' />
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step3 }
