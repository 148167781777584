import React, { Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { NotificationProvider, ErrorBoundary } from 'shared'
import { AuthProvider } from 'auth'

import { I18nProvider } from '../../../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../../../_metronic/layout/core'
import { Routes } from '../routes/Routes'

type Props = {
  basename: string
}

const AppInner: React.FC<Props> = ({ basename }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
      },
    },
  })

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <NotificationProvider>
                  <Routes />
                </NotificationProvider>
              </AuthProvider>
              {process.env.NODE_ENV === 'development' && (
                <ReactQueryDevtools initialIsOpen={false} />
              )}
            </QueryClientProvider>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

// WARNING: This error boundary does not provide a good user experience. The
// project error boundary is designed to live inside each individual route. It
// has been put here as well to handle any - hopefully rare - errors that occur
// outside the routes, and ensure they are sent to the error logger.
export const App = (props: Props) => (
  <ErrorBoundary>
    <AppInner {...props} />
  </ErrorBoundary>
)
