import React from 'react'
import { Spinner } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { Fixer } from '../../../_metronic/partials/widgets'
import { Campaigns } from '../components/Campaigns'
import { CampaignsHeader } from '../components/partials/CampaignsHeader'
import { CampaignService } from '../services'
import { ClientService } from 'client'

// TODO: this component doubles as a page (it is imported by PrivateRoutes),
// and a component inside a page (it is conditionally rendered by `HomePage`.
const MyCampaignsPage: React.FC = () => {
  const params: any = useParams()

  // TODO: document what all this is about. Is it related to this component
  // doing double duty as a page and a component within a page?
  const givenClientId = Number(params?.id)
  const clients = useQuery(['clients'], () => ClientService.getClients(), {
    enabled: !!!givenClientId,
  })
  const campaigns = useQuery('my-campaigns', CampaignService.getCampaigns)

  return (
    <>
      {campaigns.isFetching || clients.isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : campaigns.status === 'error' ? (
        <span>Error: {(campaigns.error as any).message}</span>
      ) : (
        <>
          <Fixer />
          <CampaignsHeader campaigns={campaigns.data} />
          {campaigns.isSuccess && (
            <Campaigns campaigns={campaigns.data} client={clients?.data[0]} />
          )}
        </>
      )}
    </>
  )
}

export { MyCampaignsPage }
