import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { useParams } from 'react-router-dom'
import { useNotificationContext, userTypes, ErrorBoundary } from 'shared'

import { PageTitle } from '../../../_metronic/layout/core'
import { Formik } from 'formik'
import { Spinner } from 'react-bootstrap'
import { useCheckRole, UserService, editUserSchema, EditUserDTO } from 'auth'
import { UserEditForm } from '../components/UserEditForm'
import { Client } from 'client'
import { ICampaign } from 'campaign'

type Props = { userId: string }
const EditUserPage = (props: Props) => {
  const { setNotification } = useNotificationContext()
  const queryClient = useQueryClient()
  const history = useHistory()

  const {
    status,
    data: user,
    error,
    isFetching,
  } = useQuery(['user', props.userId], () => UserService.getUser(props.userId))
  const mutation = useMutation((user: EditUserDTO) => UserService.editUser(props.userId, user), {
    onSuccess: () => {
      setNotification({
        active: true,
        message: ' You have successfully edited the user.',
        type: 'success',
      })
      queryClient.invalidateQueries(['user', props.userId])
      queryClient.refetchQueries(['users'])
    },
    onError: (e: any) => {
      setNotification({
        active: true,
        message: e.response.data || e.message,
        type: 'danger',
      })
    },
  })

  const initValues: EditUserDTO = {
    firstName: user?.firstName,
    lastName: user?.lastName,
    userType: user?.type,
    jobTitle: user?.jobTitle,
    email: user?.email,
    clients: user?.clients.map((client: Client) => client.id),
    campaigns: user?.campaigns.map((campaign: ICampaign) => campaign.id),
    identityId: user?.identityId,
    oldUserType: user?.type,
  }

  return (
    <>
      <PageTitle>Edit User</PageTitle>
      {isFetching ? (
        <div className='d-flex align-items-center justify-content-center'>
          <Spinner animation='grow'></Spinner>
        </div>
      ) : status === 'error' ? (
        <span>Error: {(error as any).message}</span>
      ) : (
        <div className='card'>
          <Formik
            initialValues={initValues}
            validationSchema={editUserSchema}
            onSubmit={async (values, actions) => {
              await mutation.mutateAsync(values as any)
              actions.resetForm()
              history.push(`/user`)
            }}
          >
            {(props) => (
              <UserEditForm form={props} mutation={mutation} values={props.values} edit={true} />
            )}
          </Formik>
        </div>
      )}
    </>
  )
}

const Controller = () => {
  useCheckRole([userTypes.Admin])
  const params: any = useParams()
  const userId = params?.id

  return <EditUserPage userId={userId} />
}

export const EditUserPageRouteHandler = () => (
  <ErrorBoundary>
    <Controller />
  </ErrorBoundary>
)
