import { useMutation, useQueryClient } from 'react-query'
import { useNotificationContext } from 'shared'
import { ContactService } from './services'

export const useDissociateContactMutation = (campaignId: number) => {
  const queryClient = useQueryClient()
  const { setNotification } = useNotificationContext()

  return useMutation(
    (contactId: number) => {
      return ContactService.dissociateContactFromCampaign(contactId, campaignId)
    },
    {
      onSuccess: () => {
        setNotification({
          active: true,
          message: 'You have successfully removed contact from campaign',
          type: 'success',
        })
        queryClient.invalidateQueries(['contacts', campaignId])
      },
      onError: (e: any) => {
        setNotification({
          active: true,
          message: e.message,
          type: 'danger',
        })
      },
    }
  )
}
