// TODO: this will be moved to the @ospace/schemas package

import * as Yup from 'yup'

const userTypeOptions = [
  {
    value: 'ClientViewer',
    label: 'Client Viewer',
  },
  {
    value: 'ClientUser',
    label: 'Client User',
  },
  {
    value: 'CampaignUser',
    label: 'Campaign User',
  },
]

const inits: any = {
  clients: [],
  campaigns: [],
  email: '',
  firstName: '',
  lastName: '',
  jobTitle: '',
  userType: undefined,
}

const createUserSchema = Yup.object({
  userType: Yup.string().required().nullable().label('User type'),
  clients: Yup.array().min(1).required('required').label('Client'),
  campaigns: Yup.array().when(['userType'], {
    is: (userTypeValue: any) => {
      if (userTypeValue === 'CampaignUser') return true
      else return false
    },
    then: Yup.array().min(1).required('required').label('Campaign'),
  }),
  email: Yup.string().required().label('Email'),
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  jobTitle: Yup.string().nullable(),
})

const editUserSchema = Yup.object({
  userType: Yup.string().required().nullable().label('User type'),
  clients: Yup.array().min(1).required('required').label('Client'),
  campaigns: Yup.array().when(['userType'], {
    is: (userTypeValue: any) => {
      if (userTypeValue === 'CampaignUser') return true
      else return false
    },
    then: Yup.array().min(1).required('required').label('Campaign'),
  }),
  email: Yup.string().required().label('Email'),
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  identityId: Yup.string(),
  oldUserType: Yup.string(),
  jobTitle: Yup.string().nullable(),
})

const editUserProfileSchema = Yup.object({
  clients: Yup.array().min(0).required('required').label('Client'),
  campaigns: Yup.array().when(['userType'], {
    is: (userTypeValue: any) => {
      if (userTypeValue === 'CampaignUser') return true
      else return false
    },
    then: Yup.array().min(0).required('required').label('Campaign'),
  }),
  email: Yup.string().required().label('Email'),
  firstName: Yup.string().required().label('First name'),
  lastName: Yup.string().required().label('Last name'),
  identityId: Yup.string(),
  jobTitle: Yup.string().nullable(),
})

const isInternalUser = (userType: string) =>
  userTypeOptions?.some((option) => option.value === userType)

type EditUserDTO = Yup.InferType<typeof editUserSchema>
type CreateUserDTO = Yup.InferType<typeof editUserSchema>
type EditUserProfileDTO = Yup.InferType<typeof editUserProfileSchema>

export {
  createUserSchema,
  editUserSchema,
  editUserProfileSchema,
  inits,
  userTypeOptions,
  isInternalUser,
}
export type { EditUserDTO, CreateUserDTO, EditUserProfileDTO }
